import { connect } from 'react-redux';
import { fetchShops, selectShop } from '../actions';
import ShopSelector from '../components/ShopSelector';


const generateDefaultFilter = (filter, shops) => {
  if (!shops || !filter || !filter.length) return '';
  const firstLink = filter[0].link;
  if (shops.length) return `${firstLink}shopID=`;
  return '';
};

const isSingleShopProject = shops => shops.initiallyFetched
  && shops.list && shops.list.length === 1;

const CurrentShopSelector = connect(
  state => ({
    isSingleShopProject: isSingleShopProject(state.shops),
    defaultFilter: generateDefaultFilter(state.projects.filter, state.shops.list),
    shops: state.shops.list ? state.shops.list.sort((a, b) => (a.name < b.name ? -1 : 1)) : [],
    showLoadingSpinner: !state.shops.initiallyFetched,
  }),
  dispatch => ({
    fetchShops: () => dispatch(fetchShops()),
    selectShop: id => dispatch(selectShop(id)),
  }),
)(ShopSelector);

export default CurrentShopSelector;
