import React from 'react';
import { Typography, Chip, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { amber, blue, red, green, grey } from '@mui/material/colors';
import { Translate } from 'react-localize-redux';
import Price from './Price';
import HeaderBar from './HeaderBar';
import { trackEvent } from './tracking';
import themeByProcessState from '../themeByProcessState';
import BackToListButton from './BackToListButton';

const styles = theme => ({
  text: {
    color: '#fff',
    marginLeft: theme.spacing(2),
  },
  price: {
    fontFamily: '\'Roboto Mono\', monospace',
    borderRight: `1px solid ${blue[500]}`,
    paddingRight: theme.spacing(2),
    fontSize: '0.875rem',
  },
  error: {
    borderColor: red[700],
  },
  success: {
    borderColor: green.A400,
    color: grey[900],
  },
  light: {
    color: grey[900],
  },
  id: {
    fontFamily: '\'Roboto Mono\', monospace',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
  },
  amount: {
    borderRadius: '4px',
    color: grey[900],
    fontWeight: '500',
    background: '#fff',
    fontSize: '0.875rem',
    marginRight: 'auto',
    fontFamily: '\'Roboto Mono\', monospace',
  },
  rescanButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginLeft: theme.spacing(2),
    background: amber[500],
    '&:hover': {
      background: amber[600],
    },

    [theme.breakpoints.down('640')]: {
      display: 'none',
    },
  },
});

function ProcessViewCartHeading({
  classes, amount, id, updating, openRescan, showRescanBtn, price, currency, processState,
}) {
  const theme = themeByProcessState(processState);
  return (
    <HeaderBar
      theme={theme}
      content={
        <React.Fragment>
          <BackToListButton theme={theme} />
          <Chip label={amount} className={classes.amount} />
          {!!price && processState !== 'approaching' &&
            <Typography className={`${classes.price} ${classes.text} ${classes[theme]}`}><Price value={price} currency={currency} /></Typography>
          }
          <Typography className={`${classes.id} ${classes.text} ${classes[theme]}`}>{id}</Typography>
          {showRescanBtn &&
          <Button
            variant="containedInherit"
            disabled={updating}
            key="button-rescan"
            className={classes.rescanButton}
            onClick={() => {
              trackEvent('processView', 'openRescanButtonHeader', `for process with state '${processState}'`);
              openRescan();
            }}>
            <Translate id="rescan.button" />
          </Button>
        }
        </React.Fragment>
    } />
  );
}

export default withStyles(styles)(ProcessViewCartHeading);
