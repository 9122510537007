import React from 'react';
import { Translate } from 'react-localize-redux';
import { Dialog, DialogContent, DialogTitle, DialogContentText } from '@mui/material';

const OfflineLayer = () => (
  <Dialog
    open
    maxWidth="xs"
    >
    <DialogTitle><Translate id="general.connectionError" /></DialogTitle>
    <DialogContent>
      <DialogContentText>
        <Translate id="general.connectionErrorDescription" />
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default OfflineLayer;
