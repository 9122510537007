import React from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Dialog, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import Scanner from './Scanner';
import NotificationAreaContainer from '../containers/NotificationAreaContainer';
import { openSettings } from '../mobileAppDetection';

const QRCodeLoginLayer = ({ redirectToLogin, loginWithScannedCredentials }) => (
  <React.Fragment>
    <Scanner onContentReceive={value => loginWithScannedCredentials(value)} />
    <Dialog
      open
      maxWidth="xs"
  >
      <DialogTitle><Translate id="login.error.missingLogin" /></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="login.error.missingLoginMessage" />
        </DialogContentText>

        <Button
          onClick={redirectToLogin}
          style={{
          padding: 0,
          textTransform: 'none',
          fontSize: '16px',
        }}>
          <Translate id="login.error.missingLoginLink" />
        </Button>
        <br />
        <Button
          onClick={openSettings}
          style={{
            padding: 0,
            textTransform: 'none',
            fontSize: '16px',
          }}>
          <Translate id="login.appSettings" />
        </Button>
      </DialogContent>
      <NotificationAreaContainer />
    </Dialog>
  </React.Fragment>
);

export default QRCodeLoginLayer;
