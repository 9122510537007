import React from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import ProcessRescanContainer from './../containers/ProcessRescanContainer';
import LoadingCircle from './LoadingCircle';
import ProcessCartView from './ProcessCartView';
import ProcessWizard from './ProcessWizard';
import { trackEvent } from '../components/tracking';

function trackDuration(category, process, shop, from) {
  const timeElapsed = moment().diff(from, 'seconds');
  const externalShopID = shop ? `${shop.name} (${shop.externalId})` : '';
  trackEvent(category, process.shopID, externalShopID, timeElapsed);
}

class ProcessView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRescan: false,
      hideWizard: false,
      supervisingResults: [],
      openedProcess: moment(),
    };

    this.toggleRescanView = this.toggleRescanView.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.selectSingleProcess();
    this.props.updatedSubscribedProcess(this.props.match.url);
    this.props.getProcess();
  }

  componentDidUpdate(prevProps) {
    const { trackWaitingTime, process, shop } = this.props;
    if (!prevProps.trackWaitingTime && trackWaitingTime) {
      trackDuration('awaitingSupervisorDuration', process, shop, process.date);
    }
  }

  componentWillUnmount() {
    this.props.updatedSubscribedProcess();
  }

  toggleRescanView(isVisible) {
    this.setState({ showRescan: isVisible });
  }

  hideWizard(supervisingResults, isApproved) {
    const { requiresSupervisorApproval } = this.props.process;
    this.setState(
      { hideWizard: true, supervisingResults },
      () => {
        if (!isApproved) {
          this.handleSubmit(false);
        } else if (!requiresSupervisorApproval) {
          this.handleSubmit(true);
        }
      },
    );
  }

  handleSupervisorApproval(isApproved) {
    const mergedChecks = [].concat(this.state.supervisingResults);

    this.setState({ supervisingResults: mergedChecks }, () => {
      this.handleSubmit(isApproved);
    });
  }

  handleSubmit(isApproved) {
    const {
      process, supervisorID, sendResult,
    } = this.props;
    const { supervisingResults, openedProcess } = this.state;

    const result = {
      checkoutID: process.id,
      supervisorID: supervisorID ? encodeURI(supervisorID) : '',
      clientID: process.clientID,
      successful: isApproved,
      checks: supervisingResults,
    };

    trackDuration('supervisorControlDuration', this.props.process, this.props.shop, openedProcess);

    this.toggleRescanView(false);
    sendResult(result, process);
  }

  render() {
    const {
      process, found, sendingApproval, match, disableRescan, showSku, showScannedCode,
    } = this.props;
    const { showRescan } = this.state;
    const showActions = process && process.state === 'pending' && process.requiresSupervisorApproval;


    if (found == null) {
      return (<LoadingCircle show />);
    }

    if (!found) {
      const shopList = `/${match.params.projectID}`;
      return <Redirect to={shopList} />;
    }

    if (process.needsWizard && !this.state.hideWizard) {
      return (<ProcessWizard
        steps={process.steps}
        onSuccessfulFinish={(supervisingResults) => {
          this.hideWizard(supervisingResults, true);
          }
        }
        onRejecting={(supervisingResults) => {
          this.hideWizard(supervisingResults, false);
        }
        }
      />);
    }

    if (showRescan) {
      return (
        <ProcessRescanContainer
          onOpenCart={() => this.toggleRescanView(false)}
          updating={sendingApproval}
          approveAction={isApproved => this.handleSupervisorApproval(isApproved)}
          showActions={showActions}
          withHeading
        />
      );
    }

    return (
      <ProcessCartView
        process={process}
        onOpenRescan={() => this.toggleRescanView(true)}
        updating={sendingApproval}
        approveAction={isApproved => this.handleSupervisorApproval(isApproved)}
        showActions={showActions}
        disableRescan={disableRescan}
        showSku={showSku}
        showScannedCode={showScannedCode}
      />
    );
  }
}

export default ProcessView;
