import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { red, green } from '@mui/material/colors';
import ProcessRescanItemActions from './ProcessRescanItemActions';

const styles = theme => ({
  item: {
    flexWrap: 'wrap',
    flexDirection: 'column',

    [theme.breakpoints.down('640')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  success: {
    background: green[100],
  },
  failure: {
    background: red[100],
  },
  successText: {
    textAlign: 'center',
    fontWeight: '500',
    color: green[900],
  },
  failureText: {
    textAlign: 'center',
    fontWeight: '500',
    color: red[900],
  },
});

class ProcessRescanItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setAmount = this.setAmount.bind(this);
  }
  setAmount(value) {
    this.props.updateCheckedItem(this.props.item.sku, value);
  }
  render() {
    const { item, classes } = this.props;
    const isSuccess = item.isInCart && item.amount >= item.foundAmount;

    return (
      <ListItem
        alignItems="center"
        disableGutters
        divider
        className={`${item.hasFailure ? classes.failure : classes.success} ${classes.item}`}>
        <ProcessRescanItemActions
          amount={item.foundAmount}
          isSuccess={isSuccess}
          setAmount={val => this.setAmount(val)}
        />
        <ListItemText
          classes={{ primary: item.hasFailure ? classes.failureText : classes.successText }}
          primary={item.name}
        />
      </ListItem>
    );
  }
}


export default withStyles(styles)(ProcessRescanItem);
