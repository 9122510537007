import React from 'react';
import { Translate } from 'react-localize-redux';
import { List, ListItem, ListItemText, Dialog, DialogContent, DialogTitle } from '@mui/material';


import withStyles from '@mui/styles/withStyles';


const styles = theme => ({
  header: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '2.31rem',
  },
  content: {
    padding: 0,
  },
  item: {
    textAlign: 'center',
    fontWeight: '500',
  },
});

const BundleSelectionLayer = ({
  items, isVisible, classes, onSelection,
}) => (
  <React.Fragment>
    <Dialog
      open={!!isVisible}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className={classes.header}><Translate id="rescan.bundle" /></DialogTitle>
      <DialogContent className={classes.content}>
        <List disablePadding>
          {(items || []).map(item => (
            <ListItem key={`${item.sku}-${item.scannedCode}`} divider onClick={() => onSelection(item)}>
              <ListItemText disableTypography className={classes.item}>{item.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  </React.Fragment>
);

export default withStyles(styles)(BundleSelectionLayer);
