import { connect } from 'react-redux';
import { selectProject } from '../actions';
import ProjectSelector from '../components/ProjectSelector';

function sortProjects(projects) {
  return (projects).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
}

const ProjectSelectorContainer = connect(
  state => ({
    foundProject: state.projects.found,
    projectSelected: state.projects.hasSelected,
    projects: sortProjects(state.projects.list),
  }),
  dispatch => ({
    selectProject: id => dispatch(selectProject(id)),
  }),
)(ProjectSelector);

export default ProjectSelectorContainer;
