import React, { memo } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { green, red, blue } from '@mui/material/colors';

const styles = theme => ({
  root: {
    width: '100%',
  },
  bar: {
    display: 'flex',
    maxWidth: '798px',
    margin: '0 auto',
    padding: 0,
    width: '100%',
  },
  headline: {
    color: '#fff',
    textAlign: 'center',
    width: '100%',
  },
  error: {
    background: red[900],
  },
  success: {
    background: green.A700,
  },
  light: {
    background: blue[50],
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
});

function HeaderBar(props) {
  const {
    classes, content, headline, theme,
  } = props;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes[theme]}>
        <Toolbar classes={{ root: classes.bar }}>
          <div className={classes.wrapper}>
            {content} {!!headline && <Typography className={classes.headline} variant="subtitle2">{headline}</Typography>}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default memo(withStyles(styles)(HeaderBar));
