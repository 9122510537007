import React from 'react';
import { Typography, List } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import ProcessRescanItem from './ProcessRescanItem';

const styles = theme => ({
  noItems: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  list: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
});

const ProcessRescanItemList = (({
  classes, items, updateCheckedItem,
}) => (
  <div className={classes.list}>
    {items.length >= 1 && (
      <List disablePadding>
        {items.map(item =>
          (<ProcessRescanItem
            updateCheckedItem={(sku, amount) => updateCheckedItem(sku, amount)}
            item={item}
            key={`${item.sku}-${item.scannedCode}`}
          />))}
      </List>
    )}

    {items.length < 1 && (
      <Typography variant="subtitle1" color="inherit" className={classes.noItems}>
        <Translate id="rescan.noItems" />
      </Typography>
    )}
  </div>
));

export default withStyles(styles)(ProcessRescanItemList);
