import React from 'react';
import HeaderBar from './HeaderBar';
import BackToListButton from './BackToListButton';

function ProcessWizardHeading({
  headline, theme,
}) {
  return (
    <HeaderBar
      theme={theme}
      headline={headline}
      content={<BackToListButton theme={theme} />
    } />
  );
}

export default ProcessWizardHeading;
