import { connect } from 'react-redux';
import { selectProject, selectShop, fetchShops } from '../actions';
import App from '../components/App';

const AppContainer = connect(
  ({ projects, subscription }, { match }) => ({
    requiresLogin: !projects.hasSelected && projects.found,
    isFetchingProjects: projects.found === null,
    hasToSelectValidProject: projects.found === false && match?.params.projectID,
    projectSelected: projects.hasSelected,
    processFilter: projects.filter,
    isOffline: subscription.isOffline,
  }),
  dispatch => ({
    selectProject: id => dispatch(selectProject(id)),
    selectShop: (id) => {
      dispatch(fetchShops());
      dispatch(selectShop(id));
    },
  }),
)(App);

export default AppContainer;
