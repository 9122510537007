import { connect } from 'react-redux';
import { resetNotification } from '../actions';
import NotificationArea from '../components/NotificationArea';

const NotificationAreaContainer = connect(
  state => ({
    open: state.notification.isVisible,
    message: state.notification.message,
    isError: state.notification.isError,
  }),
  dispatch => ({
    onClose: () => dispatch(resetNotification()),
  }),
)(NotificationArea);


export default NotificationAreaContainer;
