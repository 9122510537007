import { connect } from 'react-redux';
import { showNotification } from '../actions';
import ProcessRescanView from '../components/ProcessRescanView';

const ProcessRescanContainer = connect(
  state => ({
    items: state.processes.selected.lineItems,
    codeTemplates: state.projects.selected.codeTemplates,
    priceOverrideTemplates: state.projects.selected.priceOverrideTemplates,
    token: state.token.raw,
    project: state.projects.selectedID,
    shopID: state.shops.selectedID,
  }),
  dispatch => ({
    onError: () => dispatch(showNotification('general.requestFailed', true)),
  }),
)(ProcessRescanView);

export default ProcessRescanContainer;

