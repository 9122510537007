import React from 'react';
import ProcessWizardStepDebitCard from './ProcessWizardStepDebitCard';
import ProcessWizardStepAge from './ProcessWizardStepAge';
import { trackEvent } from './tracking';
import ProcessWizardStepRescan from './ProcessWizardStepRescan';

const wizardSteps = {
  supervisor_approval: props => <ProcessWizardStepRescan {...props} />,
  verify_debit_card: props => <ProcessWizardStepDebitCard {...props} />,
  min_age: props => <ProcessWizardStepAge {...props} />,
};

class ProcessWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      currentIndex: 0,
    };

    this.checkResult = this.checkResult.bind(this);
  }

  checkResult(type, isSuccessful, furtherInformation) {
    let result = { type, successful: isSuccessful };
    if (furtherInformation) {
      result = Object.assign({}, result, furtherInformation);
    }
    const results = [...this.state.results, result];

    trackEvent('wizard', type, `step approved: ${isSuccessful}`);

    this.setState({ results, currentIndex: this.state.currentIndex + 1 }, () => {
      if (!isSuccessful) {
        this.props.onRejecting(results);
      } else if (results.length === this.props.steps.length) {
        this.props.onSuccessfulFinish(results);
      }
    });
  }

  render() {
    const {
      steps,
    } = this.props;
    if (!steps) return '';
    return (
      <React.Fragment>
        {steps.map((check, index) => (
          wizardSteps[check.type]({
            key: check.type,
            hidden: index !== this.state.currentIndex,
            check,
            onAction: this.checkResult,
          })
        ))}
      </React.Fragment>
    );
  }
}

export default ProcessWizard;
