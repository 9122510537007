import React from 'react';
import { Translate } from 'react-localize-redux';
import { ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { grey } from '@mui/material/colors';

const styles = theme => ({
  item: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    flexWrap: 'wrap',
    flexDirection: 'column',
    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  textWrap: {
    display: 'flex',
    padding: 0,
    maxWidth: '750px',
    width: '100%',
    margin: '0 auto',
  },
  text: {
    width: '75%',
    display: 'flex',
  },
  name: {
    width: '75%',
    maxHeight: theme.spacing(8.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  condition: {
    width: '75%',
    color: grey[600],
  },
  icon: {
    color: theme.palette.primary.main,
    width: '25%',
  },
  secondary: {
    width: '35%',
  },
});


const CouponItemCell = ({
  classes, coupon,
}) => (
  <ListItem
    className={`${classes.item}`}
    divider
    disableGutters>
    <ListItemText
      classes={{ primary: classes.text, secondary: classes.secondary, root: classes.textWrap }}
      primary={
        <React.Fragment>
          <span className={classes.icon}>%</span>
          <span className={classes.name}>{coupon.name}</span>
        </React.Fragment>
      }
      secondary=""
    />

    <ListItemText
      classes={{ primary: classes.text, secondary: classes.secondary, root: classes.textWrap }}
      primary={
        <React.Fragment>
          <span className={classes.icon} />
          <span className={classes.condition}>
            {!coupon.redeemed && <Translate id="process.notRedeemed" />}
            {coupon.redeemed && <Translate id="process.redeemed" />}
          </span>
        </React.Fragment>
      }
      secondary=""
    />

  </ListItem>
);

export default withStyles(styles)(CouponItemCell);
