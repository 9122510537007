import React, { memo } from 'react';
import { Translate } from 'react-localize-redux';
import { grey } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import { LocaleDate } from './LocaleDate';
import TicketLink from './TicketLink';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'relative',
    backgroundColor: grey[900],
    color: grey[50],
    width: '100%',
    fontFamily: '\'Roboto Mono\', monospace',
    lineHeight: 1.27,
    fontSize: '1rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '& a': {
      display: 'block',
      color: grey[50],
      maxWidth: '750px',
      margin: '0 auto',
    },
    '& dl': {
      width: '100%',
      maxWidth: '750px',
      margin: '0 auto',
    },
    '& dt': {
      margin: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      [theme.breakpoints.up('320')]: {
        width: '35%',
        display: 'inline-block',
      },
    },
    '& dd': {
      display: 'inline-block',
      margin: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      [theme.breakpoints.up('320')]: {
        width: '65%',
        display: 'inline-block',
      },

    },
  },
});

const ResultProp = ({ translationKey, value }) => {
  if (!value) return null;
  return (
    <React.Fragment>
      <dt>
        <Translate id={translationKey} />
      </dt>
      <dd>
        {value}
      </dd>
    </React.Fragment>
  );
};

const isFinal = status => [
  'granted',
  'rejected',
  'aborted',
  'transferred',
  'succeeded',
  'failed',
].includes(status);

const isProcessing = status => status === 'processing';

const onMissingTranslation = ({ translationId }) => translationId.split('.')[1];

const SupervisingResult = ({
  processState, approvedAt, approvedBy, paymentMethod, classes, paymentError, date,
}) => {
  if (!isFinal(processState) && !isProcessing(processState)) {
    return (
      <div className={classes.container}>
        <TicketLink text="ticket.orderLink" />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <dl>
        <ResultProp translationKey="process.status" value={<Translate id={`states.${processState}`} />} />
        {paymentError && <ResultProp translationKey="paymentError.label" value={<Translate id={`paymentError.${paymentError}`} options={{ onMissingTranslation }} />} />}
        <ResultProp translationKey="process.date" value={LocaleDate(date)} />
        <ResultProp translationKey="process.approvedAt" value={approvedAt ? LocaleDate(approvedAt) : null} />
        <ResultProp translationKey="process.approvedBy" value={approvedBy} />
        <ResultProp translationKey="process.paymentMethod" value={<Translate id={`paymentMethods.${paymentMethod}`} options={{ onMissingTranslation }} />} />
      </dl>
      <TicketLink text="ticket.orderLink" />
    </div>
  );
};

export default memo(withStyles(styles)(SupervisingResult));
