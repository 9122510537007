import React from 'react';
import LineItemListText from './LineItemListText';

const ReferringItem = ({
  item, currency,
}) => {
  if (!item) return null;

  return (
    <LineItemListText
      lineItemText={item.name}
      price={item.totalPrice}
      currency={currency}
      isSecondary
    />
  );
};

export default (ReferringItem);
