import React, { PureComponent } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';

import englishTranslation from './../translations/en.translations.json';
import germanTranslation from './../translations/de.translations.json';
import hungarianTranslation from './../translations/hu.translations.json';
import LoginContainer from '../containers/LoginContainer';
import NotificationAreaContainer from '../containers/NotificationAreaContainer';
import LoadingCircle from './LoadingCircle';
import { getParamFromSearch } from '../actions/urls';
import OfflineLayer from './OfflineLayer';
import Routes from './Routes';

class App extends PureComponent {
  constructor(props) {
    super(props);

    const languages = [
      { name: 'English', code: 'en' },
      { name: 'German', code: 'de' },
      { name: 'Hungarian', code: 'hu' },
    ];
    let defaultLanguage = 'en';

    const userLanguage = languages.find(l => navigator.language.indexOf(l.code) !== -1);
    if (userLanguage) {
      defaultLanguage = userLanguage.code;
    }

    props.initialize({
      languages,
      options: {
        defaultLanguage,
        renderToStaticMarkup,
      },
    });

    props.addTranslationForLanguage(englishTranslation, 'en');
    props.addTranslationForLanguage(germanTranslation, 'de');
    props.addTranslationForLanguage(hungarianTranslation, 'hu');
  }

  componentDidMount() {
    const {
      projectSelected, selectProject, match, location,
    } = this.props;

    if (!projectSelected) {
      selectProject(match.params.projectID);
    }
    const shopID = getParamFromSearch(location.search, 'shopID');
    if (shopID) {
      this.props.selectShop(shopID);
    }
  }

  render() {
    const {
      hasToSelectValidProject, processFilter, isOffline, isFetchingProjects, requiresLogin,
    } = this.props;

    if (requiresLogin) {
      return (<LoginContainer />);
    }

    if (isFetchingProjects) {
      return <LoadingCircle show />;
    }

    if (hasToSelectValidProject) {
      return (<Redirect to="/" />);
    }

    return (
      <div>
        {isOffline && <OfflineLayer />}
        <Routes processFilter={processFilter} />
        <NotificationAreaContainer />
        <LoginContainer />
      </div>
    );
  }
}

export default withRouter(withLocalize(App));
