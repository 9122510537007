import React, { useMemo } from 'react';
import ProcessGridCard from './ProcessGridCard';
import './process-grid.scss';

export interface ProcessGridProps {
  scoProcesses: any[];
}

export default function ProcessGrid({ scoProcesses }: ProcessGridProps) {
  const processesGroupedByDeviceID = useMemo(() => {
    const grouped: any = {};

    scoProcesses.forEach((p: any) => {
      grouped[p.checkoutDeviceID] = [...(grouped[p.checkoutDeviceID] || []), p];
    });

    return grouped;
  }, [scoProcesses]);

  return (
    <div className="process-grid">
      {Object.keys(processesGroupedByDeviceID).map((checkoutDeviceID: any) => (
        <ProcessGridCard
          key={checkoutDeviceID}
          processes={processesGroupedByDeviceID[checkoutDeviceID]}
        />
      ))}
    </div>
  );
}
