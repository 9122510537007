import { appendToProductList, setUpdateProductState } from './helper';

export default ({ products }, action) => {
  switch (action.type) {
    case 'FETCHING_PRODUCT_BY_SKU': {
      const list = setUpdateProductState(action.payload, true, products.updateInProgressList);
      return Object.assign(
        {}, products,
        { updateInProgressList: list },
      );
    }

    case 'RECEIVED_PRODUCT_BY_SKU': {
      const product = action.payload;
      const inProgress = setUpdateProductState(product.sku, false, products.updateInProgressList);
      const list = appendToProductList(product, products.list);

      return Object.assign(
        {}, products,
        {
          list,
          updateInProgressList: inProgress,
        },
      );
    }

    case 'REQUEST_PRODUCT_BY_SCANNABLE_CODE': {
      return Object.assign(
        {}, products,
        {
          single: {},
          singleFound: null,
        },
      );
    }

    case 'RECEIVED_PRODUCT_BY_SCANNABLE_CODE': {
      return Object.assign(
        {}, products,
        {
          list: appendToProductList(action.payload, products.list),
          single: action.payload,
          singleFound: !!(action.payload && action.payload.sku),
        },
      );
    }

    case 'RECEIVED_PRODUCT_ERROR': {
      return Object.assign({}, products, {
        single: {},
        singleFound: null,
      });
    }

    case 'PROJECT_CHANGED': {
      return Object.assign(
        {}, products,
        {
          list: {},
          selected: null,
          updateInProgressList: {},
          singleFound: null,
        },
      );
    }

    default:
      return products;
  }
};
