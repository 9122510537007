import { PureComponent } from 'react';


class UpdatePageOnMobileScroll extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startPosition: 0,
      movedWay: 0,
    };

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }
  componentDidMount() {
    window.addEventListener('touchstart', this.handleTouchStart);
    window.addEventListener('touchmove', this.handleTouchMove);
    window.addEventListener('touchend', this.handleTouchEnd);
    window.addEventListener('touchcancel', this.handleTouchEnd);
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.handleTouchStart);
    window.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('touchend', this.handleTouchEnd);
    window.removeEventListener('touchcancel', this.handleTouchEnd);
  }

  handleTouchStart(event) {
    this.setState({
      startPosition: parseInt(event.changedTouches[0].clientY, 10),
    });
  }

  handleTouchMove(event) {
    const movedWay = parseInt(event.changedTouches[0].clientY, 10) - this.state.startPosition;
    this.setState({
      movedWay,
    });
    if (movedWay > 10 && window.pageYOffset <= 0) {
      this.props.onMove(movedWay);
    } else {
      this.props.onEnd();
    }
  }

  handleTouchEnd() {
    if (this.state.movedWay > 130 && window.pageYOffset <= 0) {
      document.location.reload();
    }
    this.props.onEnd();
  }

  render() {
    return null;
  }
}

export default UpdatePageOnMobileScroll;
