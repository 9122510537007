import { connect } from 'react-redux';
import { showNotification } from '../actions';
import ProcessLastUpdate from '../components/ProcessLastUpdate';

const ProcessLastUpdateContainer = connect(
  state => ({
    lastUpdate: state.processes.lastUpdate,
    startTimer: state.processes.isLoadingProcesses,
  }),
  dispatch => ({
    onTimeout: () => dispatch(showNotification('general.slowConnection', true)),
  }),
)(ProcessLastUpdate);

export default ProcessLastUpdateContainer;
