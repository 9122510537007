import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { red, green, grey } from '@mui/material/colors';
import { ArrowBackIos } from '@mui/icons-material';
import { trackEvent } from './tracking';

const styles = theme => ({
  error: {
    borderColor: red[700],
  },
  success: {
    borderColor: green.A400,
    color: grey[900],
  },
  light: {
    color: grey[900],
  },
  back: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
});

function BackToListButton({
  classes, theme,
}) {
  const history = useHistory();
  const listUrl = useSelector(state => state.subscription.listUrl);
  const backUrl = listUrl || '/';
  return (
    <ArrowBackIos
      className={`${classes.back}  ${classes[theme]}`}
      onClick={() => {
        trackEvent('processView', 'backButtonHeader');
        history.push(backUrl);
      }} />
  );
}

export default withStyles(styles)(BackToListButton);
