import moment from 'moment';

import 'moment/locale/hu';
import 'moment/locale/de';

const dateFormat = ((lang) => {
  switch (lang) {
    case 'de':
    case 'de-DE':
    case 'de-CH':
    case 'de-AT':
      moment.locale('de');
      return 'DD.MM.YYYY HH:mm';
    case 'hu':
    case 'hu-HU':
      moment.locale('hu');
      return 'YYYY.MM.DD HH:mm';
    default:
      moment.locale('en');
      return 'YYYY/MM/DD hh:mm A';
  }
})(navigator.language);

const LocaleDateFromNow = (date) => {
  moment.relativeTimeThreshold('ss', 3);
  moment.relativeTimeThreshold('m', 59);
  return moment(date).fromNow();
};

const RelativeLocaleDate = date => (moment(date).calendar());

const LocaleDate = date => (moment(date).format(dateFormat));

export { LocaleDate, RelativeLocaleDate, LocaleDateFromNow };
