import aesjs from 'aes-js';
import pbkdf2 from 'pbkdf2';
import { unpad } from 'pkcs7';
import { Buffer } from 'buffer';

function getKeys() {
  try {
    return JSON.parse(window.localStorage.deviceKeys);
  } catch (e) {
    return {};
  }
}

function setKeys(keys) {
  window.localStorage.deviceKeys = JSON.stringify(keys);
}

function getKey(keyId) {
  const keys = getKeys();
  return keys[keyId];
}

function setKey(keyId, key) {
  const keys = getKeys();
  keys[keyId] = key;
  setKeys(keys);
}

function deleteKey(keyId) {
  const keys = getKeys();
  delete keys[keyId];
  setKeys(keys);
}

function clearKeys() {
  setKeys({});
}

function decryptBuffers(passwordBuffer, cipherBuffer) {
  const salt = cipherBuffer.slice(8, 16);
  const iv = cipherBuffer.slice(16, 32);
  const encrypted = cipherBuffer.slice(32);

  try {
    const derivedKey = pbkdf2.pbkdf2Sync(passwordBuffer, salt, 10000, 32, 'sha256');
    const aesCbc = new aesjs.ModeOfOperation.cbc(derivedKey, iv); // eslint-disable-line new-cap
    const dec = aesCbc.decrypt(encrypted);
    return aesjs.utils.utf8.fromBytes(unpad(dec));
  } catch (e) {
    return undefined;
  }
}

// - We use an aes-256-cbc cipher.
// - The key is generated out of the provided passphrase using
//   pbkdf2 algorithm with a random salt, sha256 and 10000 iterations.
// - The plaintext is padded with pkcs7.
// - The result is concatenated in the following form:
//   base64('Salted__' + salt(8) + iv(8) + encrypted)
function decrypt(passwordString, cipherBase64String) {
  if (!passwordString || !cipherBase64String) return undefined;
  return decryptBuffers(Buffer.from(passwordString), Buffer.from(cipherBase64String, 'base64'));
}

export {
  getKey, decrypt, setKey, deleteKey, clearKeys,
};
