export default (subscription, action) => {
  switch (action.type) {
    case 'UPDATED_SUBSCRIBED_LIST': {
      return Object.assign(
        {}, subscription,
        {
          listUrl: action.payload,
        },
      );
    }
    case 'UPDATED_SUBSCRIBED_PROCESS': {
      return Object.assign(
        {}, subscription,
        {
          processUrl: action.payload,
        },
      );
    }
    case 'CONNECTION_ERROR': {
      return Object.assign(
        {}, subscription,
        {
          isOffline: true,
        },
      );
    }
    case 'RECONNECTED': {
      return Object.assign(
        {}, subscription,
        {
          isOffline: false,
        },
      );
    }
    default:
      return subscription;
  }
};
