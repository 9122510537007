export default (notification, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION': {
      return Object.assign(
        {}, notification,
        {
          message: action.payload.message,
          isError: action.payload.isError,
          isVisible: true,
        },
      );
    }

    case 'RESET_NOTIFICATION': {
      return Object.assign(
        {}, notification,
        {
          message: null,
          isVisible: false,
          isError: false,
        },
      );
    }

    default:
      return notification;
  }
};
