import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ProcessViewContainer from '../containers/ProcessViewContainer';
import ProcessListView from './ProcessListView';
import WebSocket from './WebSocket';

class ProcessWrapper extends Component {
  shouldComponentUpdate(prevProps) {
    // we have to check whether we shall update, otherwise every route
    // change triggers socket recreation :)
    if (this.props.shopID !== prevProps.shopID) return true;
    return (prevProps.match && prevProps.match.projectID !== this.props.match.projectID);
  }
  render() {
    return (
      <WebSocket>
        <Switch>
          <Route exact path="/:projectID/supervising/processes" component={ProcessListView} />
          <Route exact path="/:projectID/supervising/processes/:type/:id" component={ProcessViewContainer} />
        </Switch>
      </WebSocket>
    );
  }
}

export default withRouter(ProcessWrapper);
