import { get } from './request';

function receivedProjects(projects, id) {
  const project = (projects || []).find(p => p.id === id);
  if (!project) {
    return {
      type: 'PROJECT_NOT_FOUND',
      payload: { list: projects },
    };
  }
  return {
    type: 'RECEIVED_PROJECT',
    payload: { project, list: projects },
  };
}

function projectChanged(id) {
  return {
    type: 'PROJECT_CHANGED',
    payload: id,
  };
}

function fetchProjects() {
  return (dispatch, getState) => {
    const id = getState().projects.selectedID;
    get(dispatch, getState, '/supervising/projects', true)
      .then(json => dispatch(receivedProjects(json.projects, id)));
  };
}

function selectProject(id) {
  return (dispatch) => {
    dispatch(projectChanged(id));
    dispatch(fetchProjects());
  };
}

export { selectProject, fetchProjects };
