import React, { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
// @ts-ignore
import ProcessItem from './ProcessItem';
// @ts-ignore
import { updatedSubscribedList, getSupervisingProcesses } from '../actions';
import { Process } from './ProcessListView';

const getStyles = (theme: any) => ({
  processItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: `0 0 ${theme.spacing(6)}`,
  },
  noProcesses: {
    margin: 'auto',
    width: '100%',
    maxWidth: '798px',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
  },
});

export interface ProcessListProps {
  onProcessClicked: any;
  processes: Process[];
}

export default function ProcessList({ onProcessClicked, processes }: ProcessListProps) {
  const processListVisible = useSelector((state: any) =>
    state.processes.initiallyFetched && state.processes.list.length);
  const processViewIsInitializing = useSelector((state: any) => !state.processes.initiallyFetched);

  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();

  const theme = useTheme();
  const styles: any = getStyles(theme);

  useEffect(() => {
    dispatch(updatedSubscribedList(`${match.url}${location.search}`));
    dispatch(getSupervisingProcesses());
  }, [dispatch, location.search, match.url]);

  if (processViewIsInitializing) return null;

  if (processListVisible) {
    return (
      <List style={styles.processItemWrapper} id="process-list">
        {(processes || []).map((process: Process, index: number) => (
          <ProcessItem
            process={process}
            onClicked={() => onProcessClicked(process)}
            key={process.id}
            idAttr={`process-item-${index}`}
          />
        ))}
      </List>
    );
  }

  return (
    <Typography style={styles.noProcesses} variant="body1">
      <Translate id="process.noOpenCheckouts" />
    </Typography>
  );
}
