import React from 'react';
import { useStore } from 'react-redux';
import { Translate } from 'react-localize-redux';

function ticketUrl({ projects, shops, processes }) {
  const projectID = projects.selectedID;
  const shopID = shops.selectedID;
  const url = 'https://snabble.atlassian.net/servicedesk/customer/portal/1/group/2/create/6?summary=';

  if (processes?.selected?.id) {
    return url + encodeURIComponent(`Supervisor (Projekt: ${projectID}, Shop: ${shopID}, Checkout ID: ${processes.selected.id})`);
  }
  return url + encodeURIComponent(`Supervisor (Projekt: ${projectID}, Shop: ${shopID})`);
}

function TicketLink({ className, text }) {
  return (
    <a target="_blank" href={ticketUrl(useStore().getState())} className={className} rel="noreferrer" style={{ width: '100%', display: 'block' }}>
      <Translate id={text} />
    </a>
  );
}

export default TicketLink;
