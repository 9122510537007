import React, { useMemo, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProcessList from './ProcessList';
// @ts-ignore
import HeaderBar from './HeaderBar';
// @ts-ignore
import MainNavigation from '../containers/MainNavigationContainer';
// @ts-ignore
import ProcessTabs from './ProcessTabs';
// @ts-ignore
import Scanner from './Scanner';
// @ts-ignore
import LoadingCircle from './LoadingCircle';
// @ts-ignore
import join from '../path';
// @ts-ignore
import ProcessLastUpdateContainer from '../containers/ProcessLastUpdateContainer';
// @ts-ignore
import UpdatePageOnMobileScroll from '../components/UpdatePageOnMobileScroll';
// @ts-ignore
import { trackEvent } from './tracking';
// @ts-ignore
import { getParamFromSearch } from '../actions/urls';
import ProcessSplitView from './ProcessSplitView';

const findActiveTab = (history: any) => {
  if (!history) return false;
  const { location } = history;
  // select first tab if no state is present in url
  if (location.search.indexOf('state') === -1) {
    if (location.search.indexOf('splitView') === -1) return 0;
    return 3;
  }
  // select no tab
  if (!location.state || !location.state.activeTab || location.state.activeTab > 3) return false;
  // use present tab
  return location.state.activeTab;
};

export type LineItem = Record<string, any>;

export interface Process extends Record<string, any> {
  checkoutDeviceID?: string;
  date: string;
  lineItems: LineItem[];
  price: number;
  currency: string;
  state: string;
}

interface ProcessTab {
  translationKey: string;
  fallbackTranslation: string;
  link: string;
}

export default function ProcessListView() {
  const history = useHistory();
  const location = useLocation();
  const match: any = useRouteMatch();

  const wrapper = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState(findActiveTab(history));

  // TODO state needs a interface to export
  const processes = useSelector((state: any) => state.processes.list);
  const processTabs = useSelector((state: any) => state.projects.tabs);
  const shopID = useSelector((state: any) => state.shops.selectedID);
  const authorized = useSelector((state: any) => state.authorized);
  const projectPreset = useSelector((state: any) => state.projects.preset);
  const showLoadingSpinner = useSelector((state: any) => !state.processes.initiallyFetched);
  const processListFilter = useSelector((state: any) => state.projects.filter);

  const redirectToShopList = !getParamFromSearch(window.location.search, 'shopID');

  // TODO currently the route used for the view is tied to the url that is used
  // for the api call. Therefore the split screen view cannot get its own route
  // until the api calls are updated.
  // For now the split view can be activated through a url query param and is
  // defined in a component that is rendered in here.
  const splitView = getParamFromSearch(window.location.search, 'splitView') === 'true';
  const processTabsSplitView = useMemo(() => ([
    ...processTabs,
    {
      translationKey: 'sco',
      fallbackTranslation: 'SCO View',
      link: `${processTabs.find((t: ProcessTab) => t.translationKey === 'all').link}splitView=true&`,
    },
  ]), [processTabs]);

  const onProcessClicked = (process: Process, eventType: any, showRescan: Boolean = false) => {
    trackEvent('processList', eventType, `process with state '${process.state}'`);

    if (!process.links.self) return;

    history.push({
      pathname: join(process.links.self.href),
      state: { showRescan, processFilter: location.search },
    });
  };

  const lookUpProcess = (id: any) => {
    const process = processes.find((p: Process) => p.id === id);

    if (process) {
      onProcessClicked(process, 'scanProcessID', false);
    }
  };

  const handleMobileScroll = (way: any) => {
    const padding = way / 2;
    if (wrapper.current) wrapper.current.style.paddingTop = `${padding > 90 ? 90 : padding}px`;
  };

  const handleMovileScrollEnd = () => {
    if (wrapper.current) wrapper.current.style.paddingTop = '0';
  };

  const handleTabChange = (path: string, value: number) => {
    const val = value > 4 ? false : value;
    const url = path.split('?');
    history.push({
      pathname: url[0],
      search: url[1],
      state: { activeTab: val },
    });
    setActiveTab(value);
  };

  if (!authorized) {
    return null;
  }

  if (redirectToShopList) {
    const url = join(`/${match.params.projectID}`);
    return (<Redirect to={url} />);
  }

  return (
    <div
      style={{
        width: '100%',
        margin: '0 auto',
      }}
      ref={wrapper}
      id="process-wrapper"
    >

      <UpdatePageOnMobileScroll
        onMove={(way: any) => handleMobileScroll(way)}
        onEnd={() => handleMovileScrollEnd()}
      />
      <ProcessLastUpdateContainer />

      <HeaderBar content={
        <React.Fragment>
          <MainNavigation
            processListFilter={processListFilter}
            changeFilter={handleTabChange}
          />
          <ProcessTabs
            tabs={processTabsSplitView}
            shopID={shopID}
            activeTab={activeTab}
            changeTab={handleTabChange}
          />
        </React.Fragment>
      }
      />

      {projectPreset !== 'offline' &&
        <Scanner
          onContentReceive={(value: any) => lookUpProcess(value)}
          enableMobileAppCamToggle
        />}

      <LoadingCircle show={showLoadingSpinner} disableFullScreen />

      {splitView ?
        <ProcessSplitView
          onProcessClicked={(process: Process) => onProcessClicked(process, 'linkToProcess')}
          processes={processes}
        />
        :
        <ProcessList
          onProcessClicked={(process: Process) => onProcessClicked(process, 'linkToProcess')}
          processes={processes}
        />
      }
    </div >
  );
}
