/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import isEqual from 'lodash/isEqual';
// @ts-ignore
import Price from '../Price';
// @ts-ignore
import ProcessCheckIndicator from '../ProcessCheckIndicator';
import './process-grid.scss';
import { LineItem, Process } from '../ProcessListView';

export interface ProcessGridCardProps {
  processes: Process[];
}

function compareDate(a: Process, b: Process) {
  const dataB: any = new Date(b.date);
  const dateA: any = new Date(a.date);
  return dataB - dateA;
}

export default function ProcessGridCard({ processes }: ProcessGridCardProps) {
  const lineItems = useRef<LineItem[] | undefined>(undefined);
  const [isInAnimation, setIsInAnimation] = useState(false);

  const latestProcess = useMemo(() =>
    processes.sort((a: Process, b: Process) => compareDate(a, b))[0], [processes]);

  const history = useHistory();

  // Flash card if line items changed
  useEffect(() => {
    if (isEqual(latestProcess?.lineItems, lineItems.current)) return;

    setIsInAnimation(true);
    lineItems.current = latestProcess?.lineItems;
  }, [latestProcess?.lineItems]);

  const handleAnimationEnd = useCallback(() => {
    setIsInAnimation(false);
  }, []);

  const handleOpenCheckout = useCallback(() => {
    history.push({ pathname: latestProcess.links.self.href });
  }, [history, latestProcess]);

  const selectChipColor = () => {
    switch (latestProcess?.state) {
      case 'approaching':
        return { backgroundColor: '#e3f2fd' };
      case 'pending':
      case 'approved':
      case 'processing':
        return { backgroundColor: '#07b' };
      case 'rejected':
      case 'failed':
      case 'aborted':
        return { backgroundColor: '#b71c1c' };
      case 'succeeded':
      case 'transferred':
        return { backgroundColor: '#00c853' };
      default:
    }
    return {};
  };

  return (
    <div className="process-grid__card" onClick={handleOpenCheckout} onKeyPress={handleOpenCheckout} >
      <div className="process-grid__card__header">
        <span className="process-grid__card__header__status" style={selectChipColor()} />
        <span className="process-grid__card__header__title">
          ID: {latestProcess?.checkoutDeviceID?.substring(0, 4) || ''}
        </span>
        <ProcessCheckIndicator
          checks={latestProcess?.checks}
          showIndicator={latestProcess?.needsIndicators}
        />
      </div>
      <div
        className={`process-grid__card__content ${isInAnimation ? 'animate' : ''}`}
        onAnimationEnd={handleAnimationEnd}
      >
        <table>
          <tbody>
            {latestProcess?.lineItems.map((lineItem: any) => (
              <tr key={lineItem.id}>
                <td>
                  {lineItem.amount}x
                </td>
                <td style={{ width: '100%' }}>
                  {lineItem.name}
                </td>
                <td>
                  <Price value={lineItem.totalPrice} currency={latestProcess?.currency} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div >
  );
}
