import React, { useState, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import io from 'socket.io-client';
import { getSupervisingProcesses, getSupervisingProcess } from '../actions';
import logging from '../debugMode';
import { persistProjectAndShop } from '../persistProject';

const clientUri = ({ location }, project, shop) => {
  const { host } = location;
  let clientHost = `${location.protocol}//${host}`;
  if (host.startsWith('localhost')) {
    clientHost = 'https://supervisor.snabble-testing.io';
  }
  return `${clientHost}/${project}/supervising/shops/id/${shop}`;
};

export default ({ children }) => {
  const [socket, setSocket] = useState(null);
  const store = useStore();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const fetchSubscriptions = () => {
    logging.info('Sockets: "fetchSubscriptions" called');
    if (store.getState().subscription.processUrl) {
      if (store.getState().isLoadingProcess) {
        logging.info('Sockets: already getting single process,abort pending request');
        abortController.abort();
      }
      logging.info('Sockets: get single process');
      dispatch(getSupervisingProcess());
    }
    if (store.getState().subscription.listUrl) {
      if (store.getState().subscription.processUrl) {
        logging.info('Sockets: already listening to single process, skip getting list');
        return;
      }
      if (store.getState().isLoadingProcesses) {
        logging.info('Sockets: already getting process list, abort pending request');
        abortController.abort();
      }
      logging.info('Sockets: get process list');
      dispatch(getSupervisingProcesses());
    }
  };

  const closeSocket = () => {
    logging.log('Sockets: close socket', 'color: red');
    socket.close();
    setSocket(null);
  };

  const createSocket = () => {
    const { projects, shops } = store.getState();

    if (!shops.selectedID || !projects.selectedID) {
      logging.log('Sockets: shop or project ID missing, cannot create socket', 'color: red');
      return;
    }
    logging.info('Sockets: necessary information present, create socket...');

    persistProjectAndShop(projects.selectedID, shops.selectedID); // browser / android

    const client = io(
      clientUri(document, projects.selectedID, shops.selectedID),
      { forceNew: true },
    );

    client.on('shop events', () => {
      logging.log('Sockets: received "shop events"', 'color: green');
      fetchSubscriptions();
    });
    client.on('connect', () => {
      logging.log('Sockets: connect', 'color: #990');
      client.emit('authenticate', store.getState().token.raw);
      fetchSubscriptions();
      store.dispatch({ type: 'RECONNECTED' });
    });
    client.on('unauthorized', () => {
      logging.log('Sockets: unauthorized', 'color: red');
      // AL: disable es lint for console.error
      console.error('does not receive updates because client is unauthorized'); // eslint-disable-line
    });
    client.on('reconnect', () => {
      logging.log('Sockets: reconnect', 'color: #990');
      client.emit('authenticate', store.getState().token.raw);
      fetchSubscriptions();
      store.dispatch({ type: 'RECONNECTED' });
    });
    client.on('connect_error', () => {
      logging.log('Sockets: connect_error', 'color: red');
      store.dispatch({ type: 'CONNECTION_ERROR' });
    });

    setSocket(client);
  };

  useEffect(() => {
    if (!socket) {
      logging.log('Sockets: no socket present -> "createSocket"', 'color: red');
      createSocket();
    }

    return function cleanup() {
      if (socket) {
        closeSocket();
      }
    };
  });

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};
