const testingHost = 'https://api.snabble-testing.io';

const addHost = (location, url) => {
  if (url.slice(0, 4) === 'http' || !url.startsWith('/') || !location.host) {
    return url;
  }

  if (location.host.indexOf('snabble') === -1) {
    return testingHost + url;
  }

  const host = location.host.replace(/^supervisor./, 'api.');
  return `${location.protocol}//${host}${url}`;
};

const addApiHost = addHost.bind(window, window.location);

const resolveShopPlaceholder = (shop, path) => {
  let url = path;
  let correctUrl = '/';
  if (url.indexOf('{shop}') !== -1 && shop && shop.project) {
    url = url.replace(/{shop}/g, shop.project);
    correctUrl = url;
  }
  if (url.indexOf('{id}') !== -1 && shop && shop.id) {
    url = url.replace(/{id}/g, shop.id);
    correctUrl = url;
  }
  return correctUrl;
};

const getParamFromSearch = (search, param) => {
  let val;
  search.substr(1).split('&').find((p) => {
    const [k, v] = p.split('=');
    if (k === param) {
      val = v;
      return true;
    }
    return false;
  });
  return val;
};

export { addHost, addApiHost, resolveShopPlaceholder, getParamFromSearch };
