import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography, Chip, ListItem } from '@mui/material';
import { amber, red, green, grey } from '@mui/material/colors';
import Price from './Price';
import ProcessCheckIndicator from './ProcessCheckIndicator';
import ProcessStateIndicator from './ProcessStateIndicator';
import ProcessTime from './ProcessTime';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '798px',
    margin: '0 auto',
    alignItems: 'center',

    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  highlight: {
    background: amber[500],
  },
  rightSide: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  processItemCount: {
    marginLeft: '6px',
    borderRadius: '2px',
    width: '40px',
    minWidth: '40px',
    maxWidth: '40px',
    height: '24px',
    fontWeight: '500',
  },
  approachingCount: {
    backgroundColor: '#e3f2fd',
    color: grey[900],
  },
  pendingCount: {
    backgroundColor: '#07b',
    color: '#fff',
  },
  errorCount: {
    backgroundColor: red[900],
    color: '#fff',
  },
  succeededCount: {
    backgroundColor: green.A700,
    color: grey[900],
  },
  processItemId: {
    paddingLeft: '6px',
    borderLeft: `1px solid ${grey[300]}`,
    width: '50px',
    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(1.5),
    },
  },
  processPrice: {
    marginRight: '6px',
    [theme.breakpoints.up('640')]: {
      marginRight: theme.spacing(1.5),
    },
  },
  processItem: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1) / 1.5,
    borderBottom: `1px solid ${grey[300]}`,
    cursor: 'pointer',
    [theme.breakpoints.up('640')]: {
      padding: theme.spacing(1),
    },
    height: 'fit-content',
  },
  processItemWithSuspiciousUser: {
    backgroundColor: red[50],
    borderBottom: `1px solid ${red[300]}`,
  },
  stateWrapper: {
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('640')]: {
      flexDirection: 'row',
    },
  },
});

class ProcessItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleProcessClick = this.handleProcessClick.bind(this);
  }
  shouldComponentUpdate(nextProps) {
    const { process } = this.props;
    const newProcess = nextProps.process;

    return !process || !newProcess
      || newProcess.lineItemsSum !== process.lineItemsSum
      || newProcess.state !== process.state
      || newProcess.shortId !== process.shortId
      || newProcess.price !== process.price
      || newProcess.date !== process.date;
  }
  handleProcessClick() {
    const { process } = this.props;
    this.props.onClicked(process);
  }
  itemClasses() {
    const { process, classes } = this.props;
    const itemCls = [classes.processItem];

    if (process.needsIndicators) {
      itemCls.push(classes.processItem);
    }

    if (process.isSuspiciousUser) {
      itemCls.push(classes.processItemWithSuspiciousUser);
    }

    return itemCls.join(' ');
  }
  selectChipColor() {
    const {
      process, classes,
    } = this.props;
    switch (process.state) {
      case 'approaching':
        return classes.approachingCount;
      case 'pending':
      case 'approved':
      case 'processing':
        return classes.pendingCount;
      case 'rejected':
        return classes.errorCount;
      case 'succeeded':
      case 'transferred':
        return classes.succeededCount;
      case 'failed':
        return classes.errorCount;
      case 'aborted':
        return classes.errorCount;
      default:
    }
    return '';
  }
  render() {
    const {
      process, classes, idAttr,
    } = this.props;
    const id = idAttr || '';

    return (
      <ListItem className={this.itemClasses()} id={id} onClick={this.handleProcessClick}>
        <div className={classes.wrapper}>
          <Chip
            className={`${classes.processItemCount} ${this.selectChipColor()}`}
            label={process.lineItemsSum}
          />

          <div className={classes.stateWrapper}>
            <ProcessStateIndicator
              processState={process.state}
              paymentMethod={process.paymentMethod}
            />
            <ProcessCheckIndicator
              checks={process.checks}
              showIndicator={process.needsIndicators}
            />
            <ProcessTime processState={process.state} date={process.date} />
          </div>

          <div className={classes.rightSide}>
            <Typography className={classes.processPrice}>
              {!!process.price &&
                <Price value={process.price} currency={process.currency} />
              }
            </Typography>

            <span className={classes.processItemId}>
              <Typography variant="button" >
                {process.state === 'approaching' ? '' : process.shortId}
              </Typography>
            </span>
          </div>
        </div>
      </ListItem>
    );
  }
}

export default withStyles(styles)(ProcessItem);
