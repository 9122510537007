import { showNotification, resetNotification } from './notification';
import { tokenDataFromString, isAbleToUseSupervisor } from '../token';
import { selectProject } from './project';
import { trackEvent } from '../components/tracking';
import { getKey, decrypt, setKey, deleteKey, clearKeys } from '../login';
import { putSecureStorage, setJWT } from '../mobileAppDetection';


function acquiredToken(token) {
  return { type: 'ACQUIRED_TOKEN', payload: token };
}

function processingLoginCredentials(processing) {
  return { type: 'PROCESSING_LOGIN_CREDENTIALS', payload: processing };
}

function renewingLogin() {
  return { type: 'RENEWING_LOGIN' };
}

function loginFailed(dispatch, message) {
  trackEvent('login', 'usingScanner', message);
  dispatch({ type: 'AUTHORIZATION_ERROR' });
  dispatch(showNotification(message, true));
}

function login(dispatch, getState, credentials) {
  dispatch(processingLoginCredentials(true));
  fetch(
    '/login',
    {
      body: credentials,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/jwt',
      },
      method: 'POST',
    },
  ).then((response) => {
    dispatch(processingLoginCredentials(false));
    if (response.status === 200) {
      response.text()
        .then((token) => {
          const data = tokenDataFromString(token);
          if (isAbleToUseSupervisor(data)) {
            trackEvent('login', 'usingScanner', 'succeeded');
            putSecureStorage('snabbleSupervisor', credentials);
            dispatch(acquiredToken(data));
          } else {
            loginFailed(dispatch, 'Wrong Credentials');
            putSecureStorage('snabbleSupervisor', null);
          }
        })
        .then(() => dispatch(selectProject(getState().projects.selectedID)));
      return;
    }

    let message = `Communication error (Code ${response.status})`;
    if (response.status === 403) {
      message = 'Wrong Credentials';
      setJWT(null);
      putSecureStorage('snabbleSupervisor', null);
    }
    loginFailed(dispatch, message);
  });
}

const handleScannedData = (dispatch, getState, scannedDataRaw) => new Promise(() => {
  dispatch(resetNotification());

  let scannedObject;
  try {
    scannedObject = JSON.parse(scannedDataRaw);
  } catch (e) {
    trackEvent('login', 'usingScanner', 'json parse error');
    dispatch(showNotification(`Bad Token ${e}`, true));
    return;
  }

  switch (scannedObject.type) {
    case 'pwd':
      login(dispatch, getState, scannedObject.data);
      return;
    case 'aes256pwd': {
      const key = getKey(scannedObject.keyid);
      if (!key) {
        trackEvent('login', 'usingScanner', 'missing key error');
        dispatch(showNotification(`Missing device key: ${scannedObject.keyid}`, true));
        return;
      }
      const credentials = decrypt(key, scannedObject.data);
      if (!credentials) {
        trackEvent('login', 'usingScanner', 'bad token decryption error');
        dispatch(showNotification('Bad Token Decryption', true));
        return;
      }
      login(dispatch, getState, credentials);
      return;
    }
    case 'setkey':
      trackEvent('login', 'usingScanner', 'saved key');
      setKey(scannedObject.keyid, scannedObject.data);
      dispatch(showNotification(`Key saved: ${scannedObject.keyid}`, true));
      return;
    case 'delkey':
      trackEvent('login', 'usingScanner', 'deleted key');
      deleteKey(scannedObject.keyid);
      dispatch(showNotification(`Key removed: ${scannedObject.keyid}`, true));
      return;
    case 'clearkeys':
      trackEvent('login', 'usingScanner', 'deleted all keys');
      clearKeys();
      dispatch(showNotification('Removed all keys.', true));
      return;
    default:
      trackEvent('login', 'usingScanner', 'unknown type error');
      dispatch(showNotification(`Unknown type ${scannedObject.type}`, true));
  }
});

function loginWithScannedCredentials(scannedData) {
  return (dispatch, getState) => handleScannedData(dispatch, getState, scannedData);
}

function renewLogin(data) {
  return (dispatch, getState) => {
    dispatch(renewingLogin());
    if (data && data !== '') {
      login(dispatch, getState, data);
    }
  };
}

export { loginWithScannedCredentials, renewLogin };
