import React from 'react';
import { Translate } from 'react-localize-redux';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import HeaderBar from './HeaderBar';


const styles = theme => ({
  headline: {
    marginBottom: theme.spacing(2),
  },
  link: {
    marginTop: theme.spacing(2),
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(8),
  },
});

const NotFound = withStyles(styles)(({ classes }) => (
  <React.Fragment>
    <HeaderBar headline={<Translate id="general.notFound" />} />
    <div className={classes.content}>
      <Typography variant="h4" className={classes.headline}><Translate id="general.notFound" /></Typography>
      <Typography><Translate id="general.cantFind" /></Typography>
      <Typography className={classes.link}><Link to="/"><Translate id="general.home" /></Link></Typography>
    </div>
  </React.Fragment>
));

export default NotFound;
