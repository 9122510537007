import React from 'react';
import { Translate } from 'react-localize-redux';
import withStyles from '@mui/styles/withStyles';
import { Icon } from '@mui/material';
import { grey } from '@mui/material/colors';
import ProcessWizardHeading from './ProcessWizardHeading';
import { ReactComponent as Scan } from '../icons/scan.svg';
import ProcessRescanContainer from '../containers/ProcessRescanContainer';

const styles = theme => ({
  icon: {
    width: '100px',
    height: '80px',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    borderBottom: `1px solid ${grey[300]}`,
  },
});

class ProcessWizardStepRescan extends React.PureComponent {
  constructor(props) {
    super(props);
    const isPartial = props.check.method === 'partialRescan';
    this.state = {
      theme: null,
      isPartial,
      headline: isPartial ? 'rescan.partial' : 'rescan.full',
      checkedItems: [],
      todoCount: props.check.minimalCount || '', // empty string, so it looks better in translation
    };
    this.setThemeInformation = this.setThemeInformation.bind(this);
  }

  setThemeInformation() {
    const { checkedItems, isPartial } = this.state;
    const { check } = this.props;
    const hasError = !!(checkedItems || []).find(item => !item.isInCart || item.hasFailure);
    const todoCount = check.minimalCount - ((checkedItems || []).length) || '';

    let headline;
    let theme;

    if (hasError) {
      theme = 'error';
      headline = 'rescan.error';
    } else if (!isPartial || check.minimalCount !== checkedItems.length) {
      headline = isPartial ? 'rescan.partial' : 'rescan.full';
    }

    if (isPartial && check.minimalCount === checkedItems.length) {
      if (!hasError) {
        theme = 'success';
      }
      headline = 'rescan.finish';
    }

    this.setState({ theme, headline, todoCount });
  }

  render() {
    const {
      classes, hidden, check, onAction,
    } = this.props;
    const {
      theme, headline, todoCount, isPartial,
    } = this.state;
    if (hidden) return null;

    return (
      <React.Fragment>
        <ProcessWizardHeading
          theme={theme}
          headline={<Translate
            id={headline}
            data={{ count: todoCount }}
          />}
        />
        <div className={classes.content}>
          <Icon className={classes.icon}><Scan /></Icon>
        </div>
        <ProcessRescanContainer
          approveAction={(isSuccessful, itemList) => onAction(
            check.type,
            isSuccessful,
            Object.assign({}, itemList, { method: check.method }),
          )}
          isPartial={isPartial}
          showActions
          minimalCount={check.minimalCount}
          updateCheckedItems={items =>
            this.setState({ checkedItems: items }, this.setThemeInformation)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProcessWizardStepRescan);
