import { connect } from 'react-redux';
import MainNavigation from '../components/MainNavigation';
import { putSecureStorage, setJWT } from '../mobileAppDetection';

function logout() {
  setJWT(null);
  putSecureStorage('snabbleSupervisor', null);

  document.cookie = 'jwt_token=invalid; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  setTimeout(() => document.location.reload(true), 500);
}

const MainNavigationContainer = connect(state => ({
  shopID: state.shops.selectedID,
  showProjectChange: state.projects.list.length > 1,
  showShopChange: state.shops.list.length > 1,
  project: state.projects.selectedID,
  processFilter: state.projects.filter,
  logout,
}))(MainNavigation);

export default MainNavigationContainer;
