import { mapProcess } from './helper';

export default ({ processes, projects }, action) => {
  switch (action.type) {
    case 'GET_PROCESSES': {
      return Object.assign(
        {}, processes,
        {
          isLoadingProcesses: true,
        },
      );
    }

    case 'RECEIVED_PROCESSES_ERROR': {
      return Object.assign(
        {}, processes,
        {
          isLoadingProcesses: false,
          isLoadingProcess: false,
        },
      );
    }

    case 'RECEIVED_PROCESSES': {
      return Object.assign(
        {}, processes,
        {
          initiallyFetched: true,
          list: (action.payload || []).map(process => mapProcess(process, projects.selected)),
          lastUpdate: new Date(),
          isLoadingProcesses: false,
        },
      );
    }

    case 'GETTING_PROCESS': {
      return Object.assign(
        {}, processes,
        {
          isLoadingProcess: true,
        },
      );
    }

    case 'RECEIVED_SINGLE_PROCESS': {
      let process;

      if (action.payload && action.payload.id) {
        process = mapProcess(action.payload, projects.selected);
      }

      return Object.assign(
        {}, processes,
        {
          isLoadingProcess: false,
          selected: process,
          found: !!process,
          initiallyFetched: true,
        },
      );
    }

    case 'SELECTED_SINGLE_PROCESS': {
      const process = (processes.list || []).find(p => p.id === action.payload);
      if (process) {
        return Object.assign(
          {}, processes,
          {
            isLoadingProcess: false,
            selected: process,
            found: !!process,
            initiallyFetched: true,
          },
        );
      }
      return processes;
    }

    case 'SENDING_APPROVAL': {
      return Object.assign({}, processes, { isApproving: true });
    }

    case 'APPROVAL_ERROR': {
      return Object.assign({}, processes, { isApproving: false });
    }

    case 'APPROVED_PROCESS': {
      const approvedProcess = mapProcess(action.payload, projects.selected);

      return Object.assign(
        {}, processes,
        {
          isApproving: false,
          // remove state until next poll
          selected: Object.assign({}, approvedProcess, { state: '' }),
        },
      );
    }

    case 'PROJECT_CHANGED':
    case 'AUTHORIZATION_ERROR':
    {
      return Object.assign(
        {}, processes,
        {
          list: [],
          selected: null,
          found: null,
          isApproving: false,
          initiallyFetched: false,
          isLoadingProcesses: false,
        },
      );
    }

    default:
      return processes;
  }
};
