import logging from './debugMode';

// for debugging iosApp
function logIOS(log, position) {
  window.webkit.messageHandlers.ios.postMessage({ log, position });
}

function isAndroidDevice() {
  return !!window.androidInterop;
}

function isIOSDevice() {
  if (window.iosCallback) {
    return true;
  }
  return !!window.webkit
    && !!window.webkit.messageHandlers
    && !!window.webkit.messageHandlers.ios
    && !!window.webkit.messageHandlers.ios.postMessage;
}

function isMobileApp() {
  return isAndroidDevice() || isIOSDevice();
}

function androidHasFunction(key) {
  return isAndroidDevice() && !!window.androidInterop[key];
}

function iosScanEvent(code) {
  const event = new CustomEvent('iosScanSuccess', { detail: code });
  document.dispatchEvent(event);
}

function gotSecureStorageEvent(value) {
  const event = new CustomEvent('gotSecureStorageEvent', { detail: value });
  document.dispatchEvent(event);
}

function stringFromBase64(value) {
  if (!value) return '';
  try {
    return window.atob(value);
  } catch (e) {
    logging.info(e);
    return value;
  }
}


function initializeIOSCallback() {
  window.iosCallback = (funcName, value) => {
    const valueAsString = stringFromBase64(value);

    switch (funcName) {
      case 'getSecureStorage':
        gotSecureStorageEvent(valueAsString);
        break;
      case 'scannedCode':
        iosScanEvent(valueAsString);
        break;
      default:
        logging.log(`unknown iosCallback for funcName: '${funcName}'`);
        break;
    }
  };
}

function callIOSFunction(name, params) {
  if (!window.iosCallback) {
    initializeIOSCallback();
  }
  const message = Object.assign({
    interface: name,
  }, params);

  window.webkit.messageHandlers.ios.postMessage(message);
}

function iosGetSecureStorage(key) {
  const waitingForSecureStorage = new Promise(resolve => document.addEventListener('gotSecureStorageEvent', (event) => {
    if (event && event.detail) {
      resolve(event.detail);
    } else {
      resolve(null);
    }
  }, { once: true }));

  callIOSFunction('getSecureStorage', { key });

  return waitingForSecureStorage;
}

function getSecureStorage(key) {
  if (androidHasFunction('getSecureStorage')) {
    return Promise.resolve(window.androidInterop.getSecureStorage(key));
  }
  if (isIOSDevice()) {
    return iosGetSecureStorage(key).then(v => Promise.resolve(v));
  }
  return Promise.resolve(null);
}

function putSecureStorage(key, value) {
  if (androidHasFunction('putSecureStorage')) {
    return window.androidInterop.putSecureStorage(key, value);
  }
  if (isIOSDevice()) {
    return callIOSFunction('putSecureStorage', { key, value });
  }
  return null;
}

function setProjectAndShop(project, shop) {
  if (androidHasFunction('setProjectAndShop')) {
    return window.androidInterop.setProjectAndShop(project, shop);
  }
  if (isIOSDevice()) {
    return callIOSFunction('setProjectAndShop', { project, shop });
  }
  return null;
}

function getDeviceManufacturer() {
  if (androidHasFunction('getDeviceManufacturer')) {
    return window.androidInterop.getDeviceManufacturer();
  }
  return null;
}

function getDeviceModel() {
  if (androidHasFunction('getDeviceModel')) {
    return window.androidInterop.getDeviceModel();
  }
  return null;
}

// DEPRECATED! Use hasPhysicalScanner() instead
function isZebraDevice() {
  if (androidHasFunction('isZebraDevice')) {
    return window.androidInterop.isZebraDevice();
  }
  return false;
}

function hasPhysicalScanner() {
  if (androidHasFunction('hasPhysicalScanner')) {
    return window.androidInterop.hasPhysicalScanner();
  }
  return isZebraDevice();
}

function closeScanner() {
  if (androidHasFunction('closeScanner')) {
    return window.androidInterop.closeScanner();
  }
  if (isIOSDevice()) {
    return callIOSFunction('closeScanner');
  }
  return null;
}

function openScanner() {
  if (androidHasFunction('openScanner')) {
    return window.androidInterop.openScanner();
  }
  if (isIOSDevice()) {
    return callIOSFunction('openScanner');
  }
  return null;
}

function playFailSound() {
  if (androidHasFunction('playFailSound')) {
    return window.androidInterop.playFailSound();
  }
  if (isIOSDevice()) {
    return callIOSFunction('playFailSound');
  }
  return null;
}

function playSuccessSound() {
  if (androidHasFunction('playSuccessSound')) {
    return window.androidInterop.playSuccessSound();
  }
  if (isIOSDevice()) {
    return callIOSFunction('playSuccessSound');
  }
  return null;
}

function setJWT(token) {
  if (androidHasFunction('setJWT')) {
    return window.androidInterop.setJWT(token);
  }
  if (isIOSDevice()) {
    return callIOSFunction('setJWT', { token });
  }
  return null;
}

function openSettings() {
  if (androidHasFunction('openSettings')) {
    return window.androidInterop.openSettings();
  }
  if (isIOSDevice()) {
    return callIOSFunction('openSettings');
  }
  return null;
}

export {
  isIOSDevice,
  isAndroidDevice,
  isMobileApp,
  androidHasFunction,
  getSecureStorage,
  putSecureStorage,
  setProjectAndShop,
  isZebraDevice,
  closeScanner,
  openScanner,
  playFailSound,
  playSuccessSound,
  hasPhysicalScanner,
  openSettings,
  getDeviceManufacturer,
  getDeviceModel,
  logIOS,
  stringFromBase64,
  setJWT,
};
