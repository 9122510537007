import React from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Dialog, List } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Menu } from '@mui/icons-material';
import join from '../path';
import { trackEvent } from './tracking';
import { isMobileApp, openSettings } from '../mobileAppDetection';
import { persistProjectAndShop, putPersistedShopID } from '../persistProject';

import MainNavigationLink from './MainNavigationLink';
import MainNavigationClose from './MainNavigationClose';
import TicketLink from './TicketLink';

const styles = theme => ({
  btn: {
    color: '#fff',
  },
  webLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.contrastText,
  },
});

class MainNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleProjectLink = this.handleProjectLink.bind(this);
    this.handleShopLink = this.handleShopLink.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleOpen() {
    this.setState({ open: true });
    trackEvent('navigation', 'openButton');
  }
  handleClose() {
    this.setState({ open: false });
  }
  handleFilterLink(path, value) {
    trackEvent('navigation', 'link', path);
    this.handleClose();
    if (this.props.changeFilter) {
      this.props.changeFilter(path, value);
    }
  }
  handleLink(path) {
    trackEvent('navigation', 'link', path);
    this.handleClose();
    this.props.history.push(join(path));
  }
  handleShopLink() {
    putPersistedShopID('');
    this.handleLink(`/${this.props.project}`);
  }
  handleProjectLink() {
    persistProjectAndShop('', '');
    this.handleLink('/');
  }
  handleLogout() {
    persistProjectAndShop('', '');
    trackEvent('navigation', 'logout');
    this.props.logout();
  }
  render() {
    const {
      classes, processFilter, shopID, showProjectChange, showShopChange,
    } = this.props;
    return (
      <div className={classes.wrapper}>
        <IconButton
          className={classes.btn}
          onClick={this.handleOpen}
          id="main-navigation-back"
          size="large">
          <Menu />
        </IconButton>

        <Dialog open={this.state.open} onClose={this.handleClose} fullScreen keepMounted>
          <List>
            <MainNavigationClose onClick={() => {
              trackEvent('navigation', 'closeButton');
              this.handleClose();
            }} />

            {(processFilter || []).map((item, index) =>
                (
                  <MainNavigationLink
                    key={item.translationKey}
                    onClick={() => this.handleFilterLink(`${item.link}shopID=${shopID}`, index)}
                    linkText={`mainnavigation.${item.translationKey}`}
                    fallbackTranslation={item.fallbackTranslation} />
              ))}

            <MainNavigationLink>
              <TicketLink text="ticket.navigation" className={classes.webLink} />
            </MainNavigationLink>

            {showShopChange &&
              <MainNavigationLink onClick={this.handleShopLink} linkText="shopSelector.navigation" />
            }

            {showProjectChange &&
              <MainNavigationLink onClick={this.handleProjectLink} linkText="project.headline" />
            }

            {isMobileApp() &&
              <MainNavigationLink onClick={openSettings} linkText="login.appSettings" />
            }

            <MainNavigationLink onClick={this.handleLogout} linkText="login.logout" />
          </List>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MainNavigation));
