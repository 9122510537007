import React, { memo } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';
import { Translate } from 'react-localize-redux';
import { grey } from '@mui/material/colors';


const styles = theme => ({
  indicator: {
    marginLeft: theme.spacing(1),
    fontSize: '12px',
    lineHeight: 1.29,
    fontWeight: 500,
    color: grey[600],
    [theme.breakpoints.up('640')]: {
      fontSize: '14px',
    },
  },
});

const onMissingTranslation = ({ translationId }) => translationId.split('.')[1];

const showPayment = (state, paymentMethod) => {
  if (state === 'succeeded' || state === 'transferred') {
    return (<Translate id={`paymentMethods.${paymentMethod}`} options={{ onMissingTranslation }} />);
  }
  return '';
};

const ProcessStateIndicator = (({
  classes, processState, paymentMethod,
}) =>
  (
    <Typography className={classes.indicator}>
      <Translate id={`states.${processState}`} /> {showPayment(processState, paymentMethod)}
    </Typography>
  ));

export default memo(withStyles(styles)(ProcessStateIndicator));
