import { findSelectedShop } from './helper';

export default (shops, action) => {
  switch (action.type) {
    case 'RECEIVED_SHOPS': {
      return Object.assign(
        {}, shops,
        {
          initiallyFetched: true,
          list: action.payload || [],
          selected: findSelectedShop(action.payload, shops.selectedID),
        },
      );
    }

    case 'SELECTED_SHOP': {
      return Object.assign(
        {}, shops,
        {
          selectedID: action.payload,
          selected: findSelectedShop(shops.list, action.payload),
        },
      );
    }

    case 'PROJECT_CHANGED':
    case ' AUTHORIZATION_ERROR': {
      return Object.assign(
        {}, shops,
        {
          initiallyFetched: false,
          selected: {},
          list: [],
          selectedID: '',
        },
      );
    }

    case 'RECEIVED_SINGLE_PROCESS': {
      if (shops && !!action.payload.shopID && action.payload.shopID !== shops.selectedID) {
        return Object.assign(
          {}, shops,
          {
            selectedID: action.payload.shopID,
            selected: findSelectedShop(shops.list, action.payload.shopID),
          },
        );
      }

      return shops;
    }

    default:
      return shops;
  }
};
