import { connect } from 'react-redux';
import { loginWithScannedCredentials, renewLogin } from '../actions';
import Login from '../components/Login';
import { isMobileApp } from '../mobileAppDetection';

export default connect(
  state => ({
    shallRenewLogin: !state.authorized && !state.loginInProgress && !state.triedToRenew,
    showLoadingSpinner: state.loginInProgress && !state.authorized,
    redirect: !isMobileApp() && !state.authorized,
    showLayer: isMobileApp() && !state.authorized,
    token: state.token ? state.token.raw : null,

  }),
  dispatch => ({
    loginWithScannedCredentials: credentials => dispatch(loginWithScannedCredentials(credentials)),
    renewLogin: data => dispatch(renewLogin(data)),
  }),
)(Login);
