import React, { memo } from 'react';
import { CircularProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  background: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  loader: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 24,
    height: 24,
  },
  basicLoader: {
    width: '100%',
    textAlign: 'center',
    margin: '20px auto',
  },
});

const LoadingCircle = ({ show, classes, disableFullScreen }) => {
  if (!show) {
    return null;
  }

  if (disableFullScreen) {
    return (
      <div className={classes.basicLoader} id="snabble-loader">
        <CircularProgress size={24} thickness={3} />
      </div>
    );
  }

  return (
    <div className={classes.background}>
      <div className={classes.loader} id="snabble-loader">
        <CircularProgress size={24} thickness={3} />
      </div>
    </div>
  );
};

function importantPropDidNotChange(prevProps, nextProps) {
  return prevProps && prevProps.show === nextProps.show;
}

export default memo(withStyles(styles)(LoadingCircle), importantPropDidNotChange);
