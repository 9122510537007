import React from 'react';
import { ListItem, ListItemIcon, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Close } from '@mui/icons-material';


const styles = {
  link: {
    width: '100%',
    maxWidth: '798px',
    margin: '0 auto',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  close: {
    marginLeft: 'auto',
  },
};

function MainNavigationClose(props) {
  const { classes, onClick } = props;
  return (
    <ListItem divider disableGutters>
      <ListItemIcon className={classes.link}>
        <IconButton className={classes.close} onClick={onClick} size="large">
          <Close />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
}

export default withStyles(styles)(MainNavigationClose);
