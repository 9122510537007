import { createTheme } from '@mui/material';

export default createTheme(({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: '#07b' },
    secondary: { main: '#ffc400' },
    error: { main: '#e60722' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  transitions: {
    create: () => 'none',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
}));
