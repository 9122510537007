const logging = {};
const isLevelEnabled = () => window.snabbleEnableDebugging;

['info', 'error', 'warn', 'log'].forEach((level) => {
  // AL: disable es lint for console log as it is wanted
  logging[level] = (message, styles) =>
    (isLevelEnabled() ? console[level](`%c${message}`, styles) : false); // eslint-disable-line
});

export default logging;
