import { setProjectAndShop, putSecureStorage, getSecureStorage } from './mobileAppDetection';

function putPersistedProject(id) {
  putSecureStorage('project', id);
  window.localStorage.setItem('snabbleSupervisorProject', id);
}

function putPersistedShopID(id) {
  putSecureStorage('shopID', id);
  window.localStorage.setItem('snabbleSupervisorShopID', id);
}

function persistProjectAndShop(project, shopID) {
  setProjectAndShop(project, shopID);
  putPersistedProject(project);
  putPersistedShopID(shopID);
}

function getPersistedProject() {
  return new Promise((resolve) => {
    if (!!window.localStorage &&
      !!window.localStorage.getItem('snabbleSupervisorProject') &&
      window.localStorage.getItem('snabbleSupervisorProject') !== ''
    ) {
      return resolve(window.localStorage.getItem('snabbleSupervisorProject'));
    }
    return getSecureStorage('project').then((v) => {
      resolve(v);
    }); // returns null if this is not a mobile app
  });
}

function getPersistedShopID() {
  return new Promise((resolve) => {
    if (
      window.localStorage &&
      !!window.localStorage.getItem('snabbleSupervisorShopID') &&
      window.localStorage.getItem('snabbleSupervisorShopID') !== ''
    ) {
      return resolve(window.localStorage.getItem('snabbleSupervisorShopID'));
    }
    return resolve(getSecureStorage('shopID')); // returns null if this is not a mobile app
  });
}

export {
  persistProjectAndShop, getPersistedProject, getPersistedShopID,
  putPersistedProject, putPersistedShopID,
};
