import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Done, NotInterested } from '@mui/icons-material';
import { green, grey, red } from '@mui/material/colors';
import { Translate } from 'react-localize-redux';


const styles = theme => ({
  buttonBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: `${theme.spacing(2)} auto`,
    maxWidth: '798px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(6),
  },
  btnText: {
    [theme.breakpoints.down('640')]: {
      display: 'none',
    },
  },
  btnIcon: {
    display: 'none',
    [theme.breakpoints.down('640')]: {
      display: 'block',
    },
  },
  approvalButton: {
    color: grey[900],
    background: green.A700,
    marginLeft: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(1)})`,
    padding: theme.spacing(2),
    '&:hover': {
      background: green[500],
    },
    [theme.breakpoints.down('640')]: {
      position: 'fixed',
      right: 0,
      top: '104px',
      height: '30vh',
      borderRadius: '2px 0 0 2px',
      minWidth: 0,
      width: '48px',
    },
  },
  rejectButton: {
    background: red[900],
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(1)})`,
    '&:hover': {
      background: theme.palette.error.main,
    },
    [theme.breakpoints.down('640')]: {
      position: 'fixed',
      left: 0,
      top: '104px',
      height: '30vh',
      borderRadius: '0 2px 2px 0',
      minWidth: 0,
      width: '48px',
    },
  },
  actionButton: {
    fontWeight: '400',
    marginTop: theme.spacing(1),
    paddingTop: 2 * theme.spacing(1),
    paddingBottom: 2 * theme.spacing(1),
    [theme.breakpoints.up('640')]: {
      marginTop: 2 * theme.spacing(1),
    },
  },
});

const ProcessActions = withRouter(withStyles(styles)(({
  classes, approveAction, rejectAction, disableButtons,
}) => (
  <div className={classes.buttonBar}>
    <Button
      id="process-action-reject"
      disabled={disableButtons}
      variant="contained"
      key="button-reject"
      className={`${classes.actionButton} ${classes.rejectButton}`}
      onClick={() => rejectAction()}>
      <span className={classes.btnText}><Translate id="process.reject" /></span>
      <NotInterested className={classes.btnIcon} />
    </Button>

    <Button
      id="process-action-approve"
      disabled={disableButtons}
      variant="contained"
      key="button-approve"
      className={`${classes.actionButton} ${classes.approvalButton}`}
      onClick={() => approveAction()}>
      <span className={classes.btnText}><Translate id="process.approve" /></span>
      <Done className={classes.btnIcon} />
    </Button>
  </div>
)));

export default memo(withStyles(styles)(ProcessActions));
