import React, { memo } from 'react';
import { Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { amber } from '@mui/material/colors';
import { Translate } from 'react-localize-redux';
import HeaderBar from './HeaderBar';
import { trackEvent } from './tracking';

const styles = theme => ({
  text: {
    color: '#fff',
    marginRight: theme.spacing(2),
  },
  cartButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginLeft: 'auto',
    background: amber[500],
    '&:hover': {
      background: amber[600],
    },
  },
});

function ProcessRescanHeading({
  classes, updating, openCart,
}) {
  return (
    <HeaderBar content={
      <React.Fragment>
        <Typography className={classes.text} variant="subtitle2"><Translate id="rescan.button" /></Typography>
        <Button
          variant="containedInherit"
          disabled={updating}
          key="button-cart"
          className={classes.cartButton}
          onClick={() => {
            trackEvent('rescan', 'cartButtonHeader');
            openCart();
          }}>
          <Translate id="rescan.backButton" />
        </Button>
      </React.Fragment>
    } />
  );
}
export default memo(withStyles(styles)(ProcessRescanHeading));
