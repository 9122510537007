import React from 'react';
import { Translate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import LoadingCircle from './LoadingCircle';
import join from '../path';
import { getPersistedShopID, putPersistedShopID } from '../persistProject';

const styles = theme => ({
  background: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 1,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    maxWidth: theme.spacing(60),
  },
  intro: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  list: {
    cursor: 'pointer',
  },
});

class ShopSelector extends React.Component {
  componentDidMount() {
    if (this.props.showLoadingSpinner) {
      this.props.fetchShops();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.shops.length !== prevProps.shops.length) {
      this.checkIfRedirectIsNeeded();
    }
  }

  useShop(id) {
    const {
      defaultFilter, history, selectShop,
    } = this.props;

    if (defaultFilter !== '') {
      selectShop(id);
      // use replace here to make it possible to go back to prev page
      history.replace(defaultFilter + id);
    }
  }

  checkIfRedirectIsNeeded() {
    const { shops, isSingleShopProject } = this.props;

    if (isSingleShopProject) {
      this.useShop(shops[0].id);
    } else {
      getPersistedShopID().then((id) => {
        const shopExists = shops.find(shop => id === shop.id);
        if (shopExists) {
          this.useShop(id);
        } else {
          putPersistedShopID('');
        }
      });
    }
  }

  handleShopSelect(shop) {
    const { defaultFilter, selectShop } = this.props;
    selectShop(shop.id);
    this.props.history.push(join(`${defaultFilter}${shop.id}`));
  }

  render() {
    const { classes, shops, showLoadingSpinner } = this.props;

    if (showLoadingSpinner) return <LoadingCircle show />;

    return (
      <div className={classes.background} id="shop-list">
        <div className={classes.wrapper}>
          <Paper className={classes.root} elevation={4}>
            <div className={classes.intro}>
              <Typography variant="h5" component="h1">
                <Translate id="shopSelector.headline" />
              </Typography>
              <Typography component="p">
                <Translate id="shopSelector.explanation" />
              </Typography>
            </div>
            <List className={classes.list}>
              {shops.map(shop =>
                (
                  <ListItem
                    key={shop.id}
                    onClick={() => { this.handleShopSelect(shop); }}>
                    <ListItemText primary={shop.name} secondary={`${shop.street}, ${shop.zip} ${shop.city}`} />
                  </ListItem>
                ))}
              {shops.length === 0 &&
                <ListItem>
                  <ListItemText primary={<Translate id="shopSelector.noShop" />} />
                </ListItem>
              }
            </List>
          </Paper>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ShopSelector));
