import React, { PureComponent } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import CurrentShopSelector from '../containers/CurrentShopSelector';
import ProjectSelectorContainer from '../containers/ProjectSelectorContainer';
import NotFound from './NotFound';

import ProcessWrapperContainer from '../containers/ProcessWrapperContainer';

class Routes extends PureComponent {
  render() {
    const { processFilter } = this.props;
    return (
      <Switch>
        <Route exact path="/" component={ProjectSelectorContainer} />
        <Route exact path="/:projectID" component={() => <CurrentShopSelector filter={processFilter} />} />
        <Route path="/:projectID/supervising/processes" component={ProcessWrapperContainer} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
