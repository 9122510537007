import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { amber } from '@mui/material/colors';

import LineItemCell from './LineItemCell';
import CouponItemCell from './CouponItemCell';
import LineItemListText from './LineItemListText';

const styles = theme => ({
  list: {
    marginBottom: theme.spacing(3),
    width: '100%',
    paddingTop: 0,
  },
  item: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    flexWrap: 'wrap',
    flexDirection: 'column',
    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  textWrap: {
    width: '100%',
    maxWidth: '750px',
    textAlign: 'left',
    paddingTop: theme.spacing(2),
  },
  highlight: {
    background: amber[500],
  },
});

const LineItemTable = withStyles(styles)(({
  classes, lineItems, currency, showSku, showScannedCode, referringItems, unredeemedCoupons,
}) => (
  <List className={classes.list}>
    {lineItems.length === 0 &&
      <ListItem className={classes.item} divider disableGutters>
        <LineItemListText
          isSecondary
          lineItemText={<Translate id="customersInStore.emptyCart" />}
          />
      </ListItem>
    }

    {(lineItems || []).map(lineItem => (
      <LineItemCell
        lineItem={lineItem}
        currency={currency}
        key={lineItem.id}
        showSku={showSku}
        showScannedCode={showScannedCode}
        referringItems={referringItems && referringItems[lineItem.id]}
        />
      ))}


    {!!unredeemedCoupons && unredeemedCoupons.length > 0 &&
      <ListItem className={classes.item} divider>
        <ListItemText
          classes={{ root: classes.textWrap }}
          primary={<Translate id="process.unredeemedCoupons" />}
          />
      </ListItem>
      }

    {(unredeemedCoupons || []).map(coupon => (
      <CouponItemCell
        key={coupon.id}
        coupon={coupon}
        />
      ))}
  </List>
));

export default withStyles(styles)(LineItemTable);
