import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function SearchableSelect({ options, onChange }) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option.title}
      renderInput={params => <TextField {...params} autoFocus variant="outlined" />}
      onChange={onChange}
    />
  );
}
export default SearchableSelect;
