import React from 'react';
import LineItemListText from './LineItemListText';

function getText(lineItem) {
  if (lineItem.units && lineItem.units !== 0) {
    return `${lineItem.amount} ${String.fromCharCode(215)} ${lineItem.units}`;
  }
  if (lineItem.weight && lineItem.weight !== 0) {
    return `${lineItem.amount} ${String.fromCharCode(215)} ${lineItem.weight} ${lineItem.weightUnit}`;
  }
  return '';
}

const WeighableInformation = ({
  lineItem, currency,
}) => {
  if (lineItem.units || lineItem.weight) {
    return (
      <LineItemListText
        lineItemText={getText(lineItem)}
        price={lineItem.totalPrice}
        currency={currency}
        isSecondary
      />
    );
  }
  return null;
};

export default WeighableInformation;
