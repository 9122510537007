import join from '../path';

const knownCheckTypes = ['verify_debit_card', 'min_age'];
const knownRescanMethods = ['partialRescan', 'rescan'];

function appendToProductList(item, list) {
  const productList = list;
  productList[item.sku] = item;
  return productList;
}

function setUpdateProductState(sku, isUpdating, list) {
  const updating = list;
  updating[sku] = isUpdating;
  return updating;
}

function calcMinimalItems(total) {
  if (!total) return 0;
  if (total <= 3) {
    return total;
  }
  const minimal = Math.ceil(total * 0.2);
  if (minimal < 3) {
    return 3;
  }
  return minimal;
}

function isRescan(check) {
  return check.type === 'supervisor_approval' && knownRescanMethods.indexOf(check.method) >= 0;
}

function stepsWithWizard(checks, totalProducts) {
  return (checks || [])
    .filter(check =>
      knownCheckTypes.indexOf(check.type) >= 0
      || isRescan(check))
    .map((check) => {
      if (isRescan(check)) {
        return Object.assign(
          {},
          check,
          { minimalCount: calcMinimalItems(totalProducts) },
        );
      }
      return check;
    });
}

function hasOnlinePayment(paymentMethod) {
  return ['deDirectDebit', 'testSepa', 'creditCardVisa', 'creditCardMastercard'].indexOf(paymentMethod) !== -1;
}

function needsIndicators(process, hasChecks) {
  switch (process.state) {
    case 'approaching':
    case 'pending':
    case 'granted':
      return hasChecks || process.rating === 'red';
    default:
      return false;
  }
}

function sortByPrice(items) {
  return items.sort((a, b) => {
    if (a.totalPrice < b.totalPrice) {
      return 1;
    }
    return -1;
  });
}

function addToReferring(referringItems, item) {
  const items = referringItems[item.refersTo] || [];
  items.push(item);
  return items;
}

function sortLineItems(items) {
  const referringItems = {};
  const filteredItems = [];
  const unredeemedCoupons = [];
  let sum = 0;
  let hasForceControlItems = false;

  (items || []).forEach((item) => {
    if (item.forceControl) {
      hasForceControlItems = true;
    }
    if (item.type === 'coupon' && !item.redeemed) {
      unredeemedCoupons.push(item);
    } else if (item.refersTo) {
      referringItems[item.refersTo] = addToReferring(referringItems, item);
    } else if (item.type !== 'coupon') {
      sum += item.amount;
      filteredItems.push(item);
    }
  });

  return {
    items: sortByPrice(filteredItems),
    referringItems,
    unredeemedCoupons,
    sum,
    hasForceControlItems,
  };
}

function getSupervisorApproval(process) {
  return process && process.checks && process.checks.find(check => check.type === 'supervisor_approval');
}

function mapProcess(process) {
  const { id, lineItems } = process;
  const {
    items, referringItems, sum, hasForceControlItems, unredeemedCoupons,
  } = sortLineItems(lineItems);
  const supervisorApproval = getSupervisorApproval(process);
  const steps = stepsWithWizard(process.checks, items.length);
  const requiresWizard = steps.length > 0;
  return Object.assign(
    {},
    process,
    {
      shortId: id.substring(id.length - 4),
      lineItemsSum: sum,
      lineItems: items,
      referringItems,
      unredeemedCoupons,
      needsIndicators: needsIndicators(
        process,
        (requiresWizard || !!supervisorApproval || hasForceControlItems),
      ),
      needsWizard: process.state === 'pending' && requiresWizard,
      steps,
      supervisorApproval,
      requiresSupervisorApproval: !!supervisorApproval,
      hasOnlinePayment: hasOnlinePayment(process.paymentMethod),
    },
  );
}

function findSelectedShop(shops, id) {
  if (!id || !shops) return {};
  return shops.find(item => item.id === id);
}

function createProcessFilter(project, states, paymentType) {
  let filterLink = join(`/${project}/supervising/processes?`);
  if (states && states.length) {
    states.forEach((state) => {
      filterLink += `state=${state}&`;
    });
  }
  if (paymentType && paymentType.length) {
    filterLink += `paymentType=${paymentType}&`;
  }
  return filterLink;
}

function createFallbackFilter(project) {
  return {
    translationKey: 'all',
    fallbackTranslation: 'All processes',
    link: createProcessFilter(project),
  };
}

function setUpProcessFilter(filters, project) {
  const links = [];
  (filters || []).forEach((filter) => {
    links.push({
      translationKey: filter.translationKey,
      fallbackTranslation: filter.displayName,
      link: createProcessFilter(project, filter.states, filter.paymentType),
    });
  });

  // if supervising metadata gives us an empty filter array
  if (!links.length) {
    links.push(createFallbackFilter(project));
  }
  return links;
}

function setUpProcessTabs(filters) {
  return filters.slice(0, 3);
}

const mapProjects = (projects) => {
  if (!projects) return [];
  return projects.map(project => Object.assign({}, project, { title: project.name }));
};

export {
  mapProcess, findSelectedShop,
  appendToProductList, setUpdateProductState,
  setUpProcessFilter, setUpProcessTabs, mapProjects,
  needsIndicators, stepsWithWizard, sortLineItems, getSupervisorApproval,
  calcMinimalItems, isRescan,
};
