import React, { memo } from 'react';
import { Icon } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { ReactComponent as Age } from '../icons/age.svg';
import { ReactComponent as Age6 } from '../icons/age6.svg';
import { ReactComponent as Age12 } from '../icons/age12.svg';
import { ReactComponent as Age14 } from '../icons/age14.svg';
import { ReactComponent as Age16 } from '../icons/age16.svg';
import { ReactComponent as Age18 } from '../icons/age18.svg';
import { ReactComponent as Age21 } from '../icons/age21.svg';
import { ReactComponent as Card } from '../icons/card.svg';


const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    height: '18px',
    marginLeft: theme.spacing(1),
    marginTop: '-7px',
    marginBottom: '8px',
  },
  icon: {
  },
  iconCard: {
    marginTop: '-1px',
  },
});

const ProcessCheckIndicator = (({ classes, checks, showIndicator }) => {
  if (!checks || !showIndicator) return '';

  const checkIcons = {
    fsk: <Icon key="fsk" className={classes.icon}><Age height="14px" /></Icon>,
    min_age_6: <Icon key="min-age-6" className={classes.icon}><Age6 height="14px" /></Icon>,
    min_age_12: <Icon key="min-age-12" className={classes.icon}><Age12 height="14px" /></Icon>,
    min_age_14: <Icon key="min-age-14" className={classes.icon}><Age14 height="14px" /></Icon>,
    min_age_16: <Icon key="min-age-16" className={classes.icon}><Age16 height="14px" /></Icon>,
    min_age_18: <Icon key="min-age-18"className={classes.icon} ><Age18 height="14px" /></Icon>,
    min_age_21: <Icon key="min-age-21" className={classes.icon}><Age21 height="14px" /></Icon>,
    verify_debit_card: <Icon key="card" className={`${classes.icon} ${classes.iconCard}`}><Card height="14px" /></Icon>,
  };

  const icons = [];
  checks.forEach((check) => {
    let icon = check.type;
    if (check.type === 'min_age') {
      icon = `min_age_${check.requiredAge}`;
    }
    if (checkIcons[icon]) {
      icons.push(checkIcons[icon]);
    }
  });
  return (
    <div className={classes.wrapper}>
      {icons}
    </div>
  );
});

export default memo(withStyles(styles)(ProcessCheckIndicator));
