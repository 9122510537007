import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';


const styles = {
  link: {
    width: '100%',
    maxWidth: '798px',
    margin: '0 auto',

    '&:hover': {
      cursor: 'pointer',
    },
  },
};

function MainNavigationLink(props) {
  const {
    classes, linkText, fallbackTranslation, onClick, children,
  } = props;
  return (
    <ListItem divider onClick={onClick || (() => { })}>
      <ListItemText className={classes.link}>
        <Translate id={linkText} options={{ onMissingTranslation: () => fallbackTranslation }} />
        {children}
      </ListItemText>
    </ListItem>
  );
}

export default withStyles(styles)(MainNavigationLink);
