import { PureComponent } from 'react';
import { playFailSound } from '../mobileAppDetection';

// if false this browser is IE11 or something else that does not support method
const playEvent = typeof window.CustomEvent === 'function' ? new Event('playFailSound') : null;

class FailSound extends PureComponent {
  componentDidUpdate() {
    if (this.props.playSound && playEvent) {
      document.dispatchEvent(playEvent);
      playFailSound();
    }
  }
  render() {
    return null;
  }
}

export default FailSound;
