import { React, memo } from 'react';
import { Translate } from 'react-localize-redux';
import withStyles from '@mui/styles/withStyles';
import { red } from '@mui/material/colors';

const styles = theme => ({
  warning: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    backgroundColor: red[100],
    width: '100%',
    textAlign: 'left',
    fontWeight: 500,
  },

  inner: {
    width: '100%',
    maxWidth: '750px',
    margin: '0 auto',
    borderLeft: `5px solid ${red[800]}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
});

function SuspiciousUserWarning({ isSuspiciousUser, classes }) {
  if (!isSuspiciousUser) {
    return null;
  }

  return <div className={classes.warning}><div className={classes.inner}><Translate id="process.suspiciousUserWarning" /></div></div>;
}

export default memo(withStyles(styles)(SuspiciousUserWarning));
