const PROCESS_STATE_THEMES = {
  approaching: 'light',
  rejected: 'error',
  failed: 'error',
  aborted: 'error',
  succeeded: 'success',
  transferred: 'success',
};

export default function selectTheme(processState) {
  return PROCESS_STATE_THEMES[processState] || '';
}
