function getCurrencySettings(computedCurrency, referenceUnit) {
  const settings = {
    fractionDigits: 0,
    priceFormat: 1,
    priceUnit: referenceUnit ? `/${referenceUnit}` : '',
    currency: computedCurrency,
    locale: 'en-GB',
  };

  switch (computedCurrency) {
    case 'HUF':
      settings.fractionDigits = 0;
      settings.priceFormat = 1;
      settings.locale = 'hu-HU';
      break;
    case 'EUR':
      settings.fractionDigits = 2;
      settings.priceFormat = 100;
      settings.locale = 'de-DE';
      break;
    case 'RON':
      settings.fractionDigits = 2;
      settings.priceFormat = 100;
      settings.locale = 'ro-RO';
      break;
    case 'CHF':
      settings.fractionDigits = 2;
      settings.priceFormat = 100;
      settings.locale = 'de-CH';
      break;
    default:
  }

  return settings;
}

function formatNumbersByCurrency(currencySettings, value) {
  const {
    currency, fractionDigits, priceFormat, priceUnit, locale,
  } = currencySettings;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: 2,
  }).format(value / priceFormat).toString() + priceUnit;
}

function Price({ value, currency, referenceUnit }) {
  const computedCurrency = currency || 'EUR';
  const currencySettings = getCurrencySettings(computedCurrency, referenceUnit);

  if (value === null) {
    return null;
  }
  return formatNumbersByCurrency(currencySettings, value);
}

export default Price;
