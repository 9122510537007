
function resetNotification() {
  return { type: 'RESET_NOTIFICATION' };
}

function showNotification(message, isError) {
  return {
    type: 'SHOW_NOTIFICATION',
    payload: { message, isError },
  };
}

export {
  resetNotification, showNotification,
};
