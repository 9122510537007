import { initializeCodeTemplates, initializePriceOverrides } from 'npm-code-templates';
import { setUpProcessFilter, setUpProcessTabs, mapProjects } from './helper';

export default (projects, action) => {
  switch (action.type) {
    case 'RECEIVED_PROJECT': {
      const { project } = action.payload;
      const selected = {
        metaInformation: project,
        codeTemplates: project && project.codeTemplates ?
          initializeCodeTemplates(project.codeTemplates) : [],
        priceOverrideTemplates: project && project.priceOverrideCodes ?
          initializePriceOverrides(project.priceOverrideCodes) : [],
      };
      const filter = setUpProcessFilter(action.payload.project.processFilter, project.id);
      return Object.assign(
        {}, projects,
        {
          selected,
          hasSelected: !!project,
          list: mapProjects(action.payload.list) || [],
          found: true,
          filter,
          tabs: setUpProcessTabs(filter),
          features: action.payload.project.features || {},
          preset: action.payload.project.preset,
        },
      );
    }

    case 'PROJECT_NOT_FOUND':
      return Object.assign(
        {}, projects,
        {
          found: false,
          selectedID: null,
          hasSelected: false,
          list: mapProjects(action.payload.list),
          filter: [],
          tabs: [],
          features: {},
          preset: null,
        },
      );

    case 'PROJECT_CHANGED':
      return Object.assign(
        {}, projects,
        {
          selectedID: action.payload,
          selected: {},
          found: true,
        },
      );

    default:
      return projects;
  }
};
