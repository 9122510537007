import { get } from './request';

function selectShop(shop) {
  return {
    type: 'SELECTED_SHOP',
    payload: shop,
  };
}

function requestShops() {
  return {
    type: 'REQUEST_SHOPS',
  };
}

function receivedShops(shops) {
  return {
    type: 'RECEIVED_SHOPS',
    payload: shops,
  };
}

function fetchShops() {
  return (dispatch, getState) => {
    dispatch(requestShops());

    return get(dispatch, getState, '/{project}/supervising/shops', true)
      .then(
        json => dispatch(receivedShops(json.shops)),
        () => dispatch(receivedShops([])),
      );
  };
}

export { selectShop, fetchShops };
