import { get, post } from './request';
import { showNotification, resetNotification } from './notification';

function receivedError() {
  return {
    type: 'RECEIVED_PROCESSES_ERROR',
  };
}

function receivedProcesses(processes) {
  return {
    type: 'RECEIVED_PROCESSES',
    payload: processes,
  };
}

function receivedSingleProcess(process) {
  return {
    type: 'RECEIVED_SINGLE_PROCESS',
    payload: process,
  };
}

function selectSingleProcess(id) {
  return {
    type: 'SELECTED_SINGLE_PROCESS',
    payload: id,
  };
}

function approvedProcess(process) {
  return {
    type: 'APPROVED_PROCESS',
    payload: process,
  };
}

function getProcesses() {
  return {
    type: 'GET_PROCESSES',
  };
}

function afterApproval(process, isApproved, dispatch) {
  const message = isApproved ?
    'process.approvedMessage' : 'process.rejectedMessage';
  dispatch(approvedProcess(process));
  dispatch(showNotification(message, false));
}

function sendingApproval() {
  return {
    type: 'SENDING_APPROVAL',
  };
}

function approvalError() {
  return {
    type: 'APPROVAL_ERROR',
  };
}

function sendSupervisingResult(result, process) {
  return (dispatch, getState) => {
    dispatch(sendingApproval());
    post(dispatch, getState, process.links.results.href, result)
      .then(
        () => { afterApproval(process, result.successful, dispatch); },
        () => {
          dispatch(approvalError());
          dispatch(showNotification('process.errorWhileRequest', true));
        },
      );
  };
}

function gettingProcess() {
  return {
    type: 'GETTING_PROCESS',
  };
}

function getSupervisingProcess(controller) {
  return (dispatch, getState) => {
    dispatch(gettingProcess());

    get(dispatch, getState, getState().subscription.processUrl, true, controller)
      .then(
        (process) => {
          dispatch(receivedSingleProcess(process));
          if (getState().notification.isVisible) {
            dispatch(resetNotification());
          }
        },
        () => {
          dispatch(receivedError());
        },
      );
  };
}

function getSupervisingProcesses(controller) {
  return (dispatch, getState) => {
    dispatch(getProcesses());
    return get(dispatch, getState, getState().subscription.listUrl, true, controller)
      .then(
        (json) => {
          dispatch(receivedProcesses(json.processes));
          if (getState().notification.isVisible) {
            dispatch(resetNotification());
          }
        },
        () => {
          // for reducers
          dispatch(receivedError());
        },
      );
  };
}


export {
  getSupervisingProcesses, getSupervisingProcess, selectSingleProcess,
  sendSupervisingResult,
};
