import React from 'react';
import { Translate } from 'react-localize-redux';
import { Icon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { grey } from '@mui/material/colors';

import ProcessWizardHeading from './ProcessWizardHeading';
import { ReactComponent as Card } from '../icons/card.svg';
import ProcessActions from './ProcessActions';

const styles = theme => ({
  icon: {
    width: '100px',
    height: '100px',
    marginBottom: theme.spacing(10.5),
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    textAlign: 'center',
  },
  details: {
    fontSize: '1.3rem',
    fontFamily: '\'Roboto Mono\', monospace',
    wordBreak: 'break-word',
    marginBottom: theme.spacing(2),
  },
  instruction: {
    color: grey[600],
  },
});

class ProcessWizardStepAge extends React.PureComponent {
  render() {
    const {
      classes, check, hidden, onAction,
    } = this.props;
    if (hidden) return null;
    const { iban, name } = check;

    return (
      <React.Fragment>
        <ProcessWizardHeading headline={<Translate id="process.debitCardApproval" />} />

        <div className={classes.content}>
          <Icon className={classes.icon}><Card /></Icon>

          <Typography className={classes.details}>{name}</Typography>
          <Typography className={classes.details}>{iban}</Typography>

          <Typography className={classes.instruction}>
            <Translate id="process.debitCardIBAN" />
          </Typography>
        </div>

        <ProcessActions
          approveAction={() => { onAction(check.type, true); }}
          rejectAction={() => { onAction(check.type, false); }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProcessWizardStepAge);
