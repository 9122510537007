import projects from './projects';
import processes from './processes';
import products from './products';
import shops from './shops';
import authorize from './authorize';
import notification from './notification';
import subscription from './subscription';

const stateObj = (s = {
  token: {},
  supervisorID: '',
  authorized: false,
  processingLoginCredentials: null,
  loginInProgress: false,

  projects: {
    selectedID: null,
    selected: {},
    codeTemplates: [],
    priceOverrideTemplates: [],
    list: [],
    filter: [],
    tabs: [],
    found: null,
    features: {},
    preset: null,
  },

  shops: {
    initiallyFetched: false,
    selected: {},
    selectedID: null,
    list: [],
  },

  processes: {
    list: [],
    selected: null,
    found: null,
    isApproving: false,
    initiallyFetched: false,
    isLoadingProcesses: false,
    isLoadingProcess: false,
    lastUpdate: null,
  },

  approval: {
    isSending: false,
  },

  products: {
    list: {},
    selected: null, // for product rescan
    updateInProgressList: {},
    singleFound: null,
  },

  notification: {
    isVisible: false,
    message: null,
    isError: false,
  },

  subscription: {
    websocketUrl: 'http://localhost:80',
    listUrl: null,
    processUrl: null,
    isOffline: false,
  },
}, action) => Object.assign({}, s, authorize(s, action), {
  projects: projects(s.projects, action),
  processes: processes(s, action),
  shops: shops(s.shops, action),
  products: products(s, action),
  notification: notification(s.notification, action),
  subscription: subscription(s.subscription, action),
});


export default stateObj;
