import { connect } from 'react-redux';
import { selectSingleProcess, sendSupervisingResult, updatedSubscribedProcess, getSupervisingProcess } from '../actions';
import ProcessView from '../components/ProcessView';

export default connect(
  state => ({
    process: state.processes.selected,
    found: state.processes.found,
    sendingApproval: state.processes.isApproving,
    supervisorID: state.supervisorID,
    disableRescan: !!state.projects.features.disableRescan,
    authorized: state.authorized,
    showSku: state.projects.features.showSku,
    showScannedCode: state.projects.features.showScannedCode,
    shop: state.shops.selected,
    trackWaitingTime: state.processes.selected && state.processes.selected.state === 'pending',
  }),
  (dispatch, props) => ({
    selectSingleProcess: () => dispatch(selectSingleProcess(props.match.params.id)),
    updatedSubscribedProcess: url => dispatch(updatedSubscribedProcess(url)),
    getProcess: () => dispatch(getSupervisingProcess()),
    sendResult: (result, process) => dispatch(sendSupervisingResult(result, process)),
  }),
)(ProcessView);
