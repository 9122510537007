export default ({
  authorized, token, processingLoginCredentials, supervisorID, loginInProgress,
}, action) => {
  switch (action.type) {
    case 'ACQUIRED_TOKEN': {
      const t = action.payload;
      let user = '';
      if (t.grants) {
        user = t.grants.sub ? t.grants.sub : t.grants.email;
      }
      return {
        loginInProgress: false,
        token: t,
        supervisorID: user,
        authorized: true,
        processingLoginCredentials: null,
        triedToRenew: false,
      };
    }

    case 'AUTHORIZATION_ERROR': {
      return {
        loginInProgress: false,
        authorized: false,
        token: {},
        granted: {},
        supervisorID: '',
        processingLoginCredentials: null,
      };
    }

    case 'PROCESSING_LOGIN_CREDENTIALS': {
      return {
        loginInProgress: true,
        authorized: false,
        token: {},
        processingLoginCredentials: action.payload,
      };
    }

    case 'RENEWING_LOGIN': {
      return {
        triedToRenew: true,
      };
    }

    default:
      return {
        authorized, token, processingLoginCredentials, supervisorID, loginInProgress,
      };
  }
};
