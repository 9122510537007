import React, { PureComponent } from 'react';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { hasPhysicalScanner, openScanner, closeScanner, isMobileApp } from '../mobileAppDetection';

const styles = theme => ({
  button: {
    width: `calc(100% - ${theme.spacing(4)})`,
    margin: theme.spacing(2),
  },
});

class Scanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      camIsVisible: false,
      isAppWithCamera: !hasPhysicalScanner() && isMobileApp(),
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleMultiKeyEvent = this.handleMultiKeyEvent.bind(this);
    this.handleValue = this.handleValue.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keypress', this.handleKeyPress);
    document.addEventListener('androidScanSuccess', this.handleMultiKeyEvent, false);
    document.addEventListener('iosScanSuccess', this.handleMultiKeyEvent, false);

    if (!this.props.enableMobileAppCamToggle && !hasPhysicalScanner()) {
      openScanner();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress);
    document.removeEventListener('androidScanSuccess', this.handleMultiKeyEvent, false);
    document.removeEventListener('iosScanSuccess', this.handleMultiKeyEvent, false);

    if (!hasPhysicalScanner()) {
      closeScanner();
    }
  }
  toggleAndroidCam() {
    const { camIsVisible } = this.state;
    if (hasPhysicalScanner()) return;
    if (camIsVisible) {
      closeScanner();
    } else {
      openScanner();
    }
    this.setState({ camIsVisible: !camIsVisible });
  }
  handleValue(value) {
    this.setState({ value: '' });
    this.props.onContentReceive(value);
  }
  handleMultiKeyEvent(event) {
    if (!event.detail) return;
    this.props.onContentReceive(event.detail);
  }
  handleKeyPress(event) {
    const { code, keyCode, key } = event;

    // ZEBRA DEVICE
    if (key === 'Unidentified') {
      return null;
    }
    // END ZEBRA DEVICE

    const { value } = this.state;
    const keyValue = String.fromCharCode(keyCode);

    if (code === 'Enter') {
      return this.handleValue(value);
    }

    if (keyValue) {
      return this.setState({ value: value + keyValue });
    }

    return null;
  }
  render() {
    if (this.state.isAppWithCamera && this.props.enableMobileAppCamToggle) {
      return (
        <Button
          variant="contained"
          className={this.props.classes.button}
          onClick={() => this.toggleAndroidCam()}>
          {!this.state.camIsVisible && <Translate id="general.openScanner" />}
          {this.state.camIsVisible && <Translate id="general.closeScanner" />}
        </Button>
      );
    }
    return null;
  }
}

export default withStyles(styles)(Scanner);
