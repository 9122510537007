import React from 'react';
import { Translate } from 'react-localize-redux';
import { Icon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { grey } from '@mui/material/colors';

import moment from 'moment';

import ProcessWizardHeading from './ProcessWizardHeading';
import ProcessActions from './ProcessActions';
import { ReactComponent as Age6 } from '../icons/age6.svg';
import { ReactComponent as Age12 } from '../icons/age12.svg';
import { ReactComponent as Age14 } from '../icons/age14.svg';
import { ReactComponent as Age16 } from '../icons/age16.svg';
import { ReactComponent as Age18 } from '../icons/age18.svg';
import { ReactComponent as Age21 } from '../icons/age21.svg';

import { RelativeLocaleDate } from './LocaleDate';


const styles = theme => ({
  icon: {
    width: '100px',
    height: '100px',
    marginBottom: theme.spacing(10.5),
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(7),
    flexDirection: 'column',
  },
  date: {
    fontSize: '3rem',
    fontFamily: '\'Roboto Mono\', monospace',
  },
  instruction: {
    color: grey[600],
  },
});

class ProcessWizardStepAge extends React.PureComponent {
  constructor(props) {
    super(props);
    const date = moment().subtract(props.check.requiredAge, 'years');
    this.state = {
      bornBeforeOrOn: date.format('YYYY/MM/DD'),
      date: RelativeLocaleDate(date.add(1, 'days')),
    };
  }
  render() {
    const {
      classes, onAction, hidden, check,
    } = this.props;

    const { bornBeforeOrOn } = this.state;

    if (hidden) return null;

    const icons = {
      min_age_6: <Icon key="min-age-6" className={classes.icon}><Age6 /></Icon>,
      min_age_12: <Icon key="min-age-12" className={classes.icon}><Age12 /></Icon>,
      min_age_14: <Icon key="min-age-14" className={classes.icon}><Age14 /></Icon>,
      min_age_16: <Icon key="min-age-16" className={classes.icon}><Age16 /></Icon>,
      min_age_18: <Icon key="min-age-18"className={classes.icon} ><Age18 /></Icon>,
      min_age_21: <Icon key="min-age-21" className={classes.icon}><Age21 /></Icon>,
    };

    return (
      <React.Fragment>
        <ProcessWizardHeading headline={<Translate id="process.ageApproval" />} />
        <div className={classes.content}>
          {icons[`min_age_${check.requiredAge}`]}

          <Typography className={classes.instruction}>
            <Translate id="process.bornBefore" />
          </Typography>

          <Typography className={classes.date}>
            {this.state.date}
          </Typography>
        </div>

        <ProcessActions
          approveAction={() => {
            onAction(
              check.type,
              true,
              { verifiedAge: check.requiredAge, bornBeforeOrOn },
            );
          }}
          rejectAction={() => { onAction(check.type, false); }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProcessWizardStepAge);
