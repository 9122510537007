import { addApiHost } from './urls';
import { showNotification } from './notification';

export class APIError extends Error {
  constructor(response) {
    super(`API Error: Status ${response.status}`);
    this.response = response;
  }
}

const fetchWithTimeout = (url, options, controller, timeout = 30000) =>
  new Promise((resolve, reject) => {
    const { signal } = controller;

    const timer = setTimeout(
      () => {
        controller.abort();
      },
      timeout,
    );

    fetch(url, Object.assign({}, options, { signal })).then(
      response => resolve(response),
      err => reject(err),
    ).finally(() => clearTimeout(timer));
  });

function requestHandler(dispatch, resolve, reject, responseOnNotFound) {
  return (response) => {
    if (response.status >= 200 && response.status < 300) {
      resolve(response.json());
      return;
    }

    if (response.status === 401 || response.status === 403) {
      dispatch({ type: 'AUTHORIZATION_ERROR' });
      return;
    }

    if (response.status === 404 && responseOnNotFound) {
      resolve({});
      return;
    }

    dispatch(showNotification(`Server response error. (Code: ${response.status})`, true));
    reject(new APIError(response));
  };
}

function errorHandler(dispatch, reject) {
  return (error) => {
    dispatch(showNotification(`Server communication error. (${error})`, true));
    reject(error);
  };
}

const resolveProjectPlaceholder = (getState, url) => {
  if (url.indexOf('{project}') !== -1) {
    const projectID = getState().projects.selectedID;
    return url.replace(/{project}/g, projectID);
  }
  return url;
};

const resolveURL = (getState, url) => addApiHost(resolveProjectPlaceholder(getState, url));

const post = (dispatch, getState, url, body, controller) =>
  new Promise((resolve, reject) => {
    let abortController = controller;
    if (!abortController) {
      abortController = new AbortController();
    }
    fetchWithTimeout(
      resolveURL(getState, url),
      {
        body: JSON.stringify(body),
        headers: {
          'Client-Token': getState().token.raw,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
      abortController,
    )
      .then(
        requestHandler(dispatch, resolve, reject),
        errorHandler(dispatch, reject),
      );
  });

const patch = (dispatch, getState, url, body, controller) =>
  new Promise((resolve, reject) => {
    let abortController = controller;
    if (!abortController) {
      abortController = new AbortController();
    }
    fetchWithTimeout(
      resolveURL(getState, url),
      {
        body: JSON.stringify(body),
        headers: {
          'Client-Token': getState().token.raw,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      },
      abortController,
    )
      .then(
        requestHandler(dispatch, resolve, reject),
        errorHandler(dispatch, reject),
      );
  });


const get = (dispatch, getState, url, responseOnNotFound, controller) =>
  new Promise((resolve, reject) => {
    let abortController = controller;
    if (!abortController) {
      abortController = new AbortController();
    }
    fetchWithTimeout(
      resolveURL(getState, url),
      {
        headers: {
          'Client-Token': getState().token.raw,
          Accept: 'application/json',
        },
        method: 'GET',
      },
      abortController,
    )
      .then(
        requestHandler(dispatch, resolve, reject, responseOnNotFound),
        errorHandler(dispatch, reject),
      );
  });

export { get, patch, post, fetchWithTimeout };
