import React from 'react';
import LoadingCircle from './LoadingCircle';
import QRCodeLoginLayer from './QRCodeLoginLayer';
import { hasPhysicalScanner, closeScanner, getSecureStorage, setJWT } from '../mobileAppDetection';

const redirectToLogin = () => {
  // close scanner before redirecting to login
  // (unmount of scanner component is not fired on location change)
  if (!hasPhysicalScanner()) {
    closeScanner();
  }
  window.location = `/login?backTo=${encodeURIComponent(window.location.href)}`;
};

class Login extends React.Component {
  componentDidMount() {
    const { token } = this.props;
    if (token) {
      setJWT(token);
    }
  }
  componentDidUpdate() {
    const { token, renewLogin, shallRenewLogin } = this.props;
    if (shallRenewLogin) {
      getSecureStorage('snabbleSupervisor').then((value) => {
        if (value && value !== '') {
          renewLogin(value);
        }
      });
    }
    if (token) {
      document.cookie = `jwt_token=${token}; path=/;`;
      setJWT(token);
    }
  }
  render() {
    const {
      loginWithScannedCredentials, showLoadingSpinner, showLayer, redirect,
    } = this.props;

    if (redirect) {
      redirectToLogin();
      return null;
    }

    if (showLoadingSpinner) {
      return (<LoadingCircle show={showLoadingSpinner} />);
    }

    if (showLayer) {
      return (
        <QRCodeLoginLayer
          redirectToLogin={() => redirectToLogin()}
          loginWithScannedCredentials={value => loginWithScannedCredentials(value)}
        />
      );
    }

    return null;
  }
}

export default Login;
