import React from 'react';
import { withRouter } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';
import { Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import join from '../path';
import englishTranslation from './../translations/en.translations.json';
import germanTranslation from './../translations/de.translations.json';
import SearchableSelect from './SearchableSelect';
import { getPersistedProject, putPersistedProject } from '../persistProject';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    maxWidth: theme.spacing(60),
  },
  intro: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  select: {
    margin: theme.spacing(2),
  },
});

class ProjectSelector extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslationForLanguage(englishTranslation, 'en');
    this.props.addTranslationForLanguage(germanTranslation, 'de');
    this.onSelectProject = this.onSelectProject.bind(this);
  }

  componentDidMount() {
    const { projects } = this.props;
    if (projects.length === 1) {
      const project = projects[0];
      this.onSelectProject(project.id);
    } else {
      getPersistedProject().then((id) => {
        const projectExists = projects.find(project => id === project.id);
        if (projectExists) {
          this.onSelectProject(id);
        } else {
          putPersistedProject('');
        }
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps || nextProps.projects.length !== this.props.projects.length;
  }

  onSelectProject(projectID) {
    const { selectProject, history } = this.props;
    selectProject(projectID);
    history.push(join(`/${projectID}`));
  }

  render() {
    const { props } = this;
    const {
      projects, classes,
    } = props;

    if (projects && projects.length > 0) {
      return (
        <div className={classes.wrapper}>
          <Paper className={classes.root} elevation={4}>
            <div className={classes.intro}>
              <Typography variant="h5" component="h1">
                <Translate id="project.headline" />
              </Typography>
              <Typography component="p">
                <Translate id="project.text" />
              </Typography>
            </div>
            <div className={classes.select}>
              <SearchableSelect
                options={projects}
                onChange={(_, project) => {
                this.onSelectProject(project.id);
              }}
              />
            </div>
          </Paper>
        </div>
      );
    }

    return (
      <div className={classes.wrapper}>
        <Paper className={classes.root} elevation={4}>
          <div className={classes.intro}>
            <Typography component="p">
              <Translate id="project.support" />
            </Typography>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(withLocalize(withStyles(styles)(ProjectSelector)));
