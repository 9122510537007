import React from 'react';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { amber } from '@mui/material/colors';
import { Translate } from 'react-localize-redux';
import LineItemList from './LineItemList';
import ProcessCartHeading from './ProcessCartHeading';
import SupervisingResult from './SupervisingResult';
import ProcessActions from './ProcessActions';
import { trackEvent } from './tracking';
import SuspiciousUserWarning from './SuspiciousUserWarning';

const styles = theme => ({
  paymentHeadline: {
    width: '100%',
    marginBottom: theme.spacing(1.5),
  },
  processView: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xl')]: {
      margin: '0 auto',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('640')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  rescanButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'fixed',
    bottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)})`,
    display: 'block',
    background: amber[500],
    '&:hover': {
      background: amber[600],
    },

    [theme.breakpoints.up('640')]: {
      display: 'none',
    },
  },
});

function ProcessCartView({
  classes, process, onOpenRescan, updating, approveAction,
  showActions, disableRescan, showScannedCode, showSku,
}) {
  const {
    state, currency, lineItemsSum, shortId, price, approvedAt,
    approvedBy, paymentMethod, lineItems, id, paymentError, referringItems,
    unredeemedCoupons, isSuspiciousUser, date,
  } = process;
  const showRescanBtn = state !== 'approaching' && !disableRescan;

  return (
    <React.Fragment>
      <div className={classes.processView}>
        <ProcessCartHeading
          amount={lineItemsSum}
          id={state === 'approaching' ? '' : shortId}
          openRescan={onOpenRescan}
          price={price}
          showRescanBtn={showRescanBtn}
          currency={currency}
          updating={updating}
          processState={state}
        />
        <SuspiciousUserWarning isSuspiciousUser={isSuspiciousUser} />
        <SupervisingResult
          processState={state}
          approvedAt={approvedAt}
          approvedBy={approvedBy}
          paymentMethod={paymentMethod}
          processID={id}
          paymentError={paymentError}
          date={date}
        />
        <LineItemList
          referringItems={referringItems}
          unredeemedCoupons={unredeemedCoupons}
          showScannedCode={showScannedCode}
          showSku={showSku}
          lineItems={lineItems}
          currency={currency} />
      </div>

      {showRescanBtn &&
        <Button
          variant="contained"
          disabled={updating}
          key="button-rescan"
          className={classes.rescanButton}
          onClick={() => {
            trackEvent('processView', 'openRescanButtonContent', `for process with state '${state}'`);
            onOpenRescan();
          }}
        >
          <Translate id="rescan.button" />
        </Button>
      }

      {showActions &&
        <ProcessActions
          approveAction={() => {
            trackEvent('processView', 'approveButton');
            approveAction(true);
          }}
          rejectAction={() => {
            trackEvent('processView', 'rejectButton');
            approveAction(false);
          }}
          disableButtons={updating}
        />
      }
    </React.Fragment>
  );
}
export default withStyles(styles)(ProcessCartView);
