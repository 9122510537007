import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocaleDateFromNow } from '../components/LocaleDate';

const styles = theme => ({
  indicator: {
    marginLeft: theme.spacing(1),
    fontSize: '12px',
    lineHeight: 1.29,
    color: grey[600],
    [theme.breakpoints.up('640')]: {
      marginLeft: '5px',
      fontSize: '14px',
    },
  },
});

class ProcessTime extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      time: LocaleDateFromNow(props.date),
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.startUpdateTimer();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timer);
    this.timer = null;
  }

  startUpdateTimer() {
    this.timer = setTimeout(() => {
      if (!this.mounted) return;
      this.setState({ time: LocaleDateFromNow(this.props.date) });
      this.startUpdateTimer();
    }, 5000);
  }

  render() {
    const { processState, classes } = this.props;
    if (processState === 'approaching' || processState === 'pending') return null;
    return (
      <Typography className={classes.indicator}>
        {this.state.time}
      </Typography>
    );
  }
}


export default withStyles(styles)(ProcessTime);
