import React from 'react';
import { findMatchingTemplates } from 'npm-code-templates';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { Typography, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ProcessRescanItemList from './ProcessRescanItemList';
import ProcessRescanHeading from './ProcessRescanHeading';
import ProcessActions from './ProcessActions';
import FailSound from './FailSound';
import SuccessSound from './SuccessSound';
import Scanner from './Scanner';
import { trackEvent } from './tracking';
import { openScanner, closeScanner, hasPhysicalScanner } from '../mobileAppDetection';

import lookUpWithTemplates from '../asyncProductLookUpByTemplates';
import BundleSelectionLayer from './BundleSelectionLayer';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  wrapper: {
    '&:focus': {
      outline: 'none',
    },
  },
  loading: {
    margin: theme.spacing(4),
    textAlign: 'center',
  },
  status: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      margin: '0 auto',
      justifyContent: 'center',
      maxWidth: 'calc(100% - 96px - 12px)', // 96 = 2 * button width, 12 = space
    },
  },
});

class ProcessRescan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: '',
      checkedItems: [],
      isValidating: false,
      foundInCart: null,
    };
    this.wrappingElement = React.createRef();

    this.updateCheckedItem = this.updateCheckedItem.bind(this);
    this.handleBundleSelection = this.handleBundleSelection.bind(this);
  }

  componentDidMount() {
    this.wrappingElement.current.focus();
    if (!hasPhysicalScanner()) {
      openScanner();
    }
  }

  componentWillUnmount() {
    if (!hasPhysicalScanner()) {
      closeScanner();
    }
  }

  addCheckedItem(item, found, code) {
    const { checkedItems } = this.state;
    const expectedAmount = item.amount || 0;
    checkedItems.unshift({
      sku: item.sku,
      name: item.name,
      isInCart: found,
      foundAmount: 1,
      amount: expectedAmount,
      scannedCode: code,
      hasFailure: expectedAmount === 0,
    });
    this.setState({ checkedItems, isValidating: false });
  }

  updateCheckedItem(sku, amount) {
    const items = [...this.state.checkedItems];
    const { updateCheckedItems } = this.props;

    let itemIndex = 0;
    const checkedItem = items.find((item, index) => {
      if (item.sku === sku) {
        itemIndex = index;
        return item;
      }
      return null;
    });

    if (amount === 0) {
      items.splice(itemIndex, 1);
    } else {
      checkedItem.foundAmount = amount;
      checkedItem.hasFailure = amount > checkedItem.amount;
    }
    this.setState({ checkedItems: items, isValidating: false });
    if (updateCheckedItems) {
      updateCheckedItems(items);
    }
  }

  showNotFound(code) {
    this.setState({ notFound: code, isValidating: false, foundInCart: false });
  }

  handleBundleSelection(item) {
    this.setState({ bundles: [], showBundles: false });
    const code = item.codes && item.codes.length ? item.codes[0].code : '';
    this.validateItemBySku(item, code);
  }

  handleItemFromApi(product, code) {
    if (product.bundles && product.bundles.length) {
      const bundles = [...product.bundles];
      // also make the single item available
      bundles.push(product);
      this.setState({ showBundles: true, bundles });
      return;
    }
    this.validateItemBySku(product, code);
  }

  validateItemBySku(product, code) {
    const { items } = this.props;
    let item = (items || []).find(i => i.sku === product.sku); // is item in cart?
    const found = !!item;
    // if item is not in cart we use fetched information to go on
    if (!found) item = product;
    this.handleCheckedItems(item, found, code);
  }

  handleCheckedItems(item, found, code) {
    const { checkedItems } = this.state;
    const { updateCheckedItems } = this.props;
    // we may already have this item in the check ones
    const checkedItemInfo = checkedItems.find(i => i.sku === item.sku);
    // if item is new add it to list
    if (!checkedItemInfo) {
      this.setState({ foundInCart: found });
      this.addCheckedItem(item, found, code);
    }
    this.setState({ isValidating: false });
    if (updateCheckedItems) {
      updateCheckedItems(checkedItems);
    }
  }

  validateItem(code) {
    if (this.state.isValidating) return;

    trackEvent('rescan', 'scannerReceivedConent', code);

    const {
      items, codeTemplates, priceOverrideTemplates,
    } = this.props;
    this.setState({ isValidating: true, notFound: '', foundInCart: null });

    // is item already in checkedItems?
    const { checkedItems } = this.state;
    let item = (checkedItems || []).find(i => i.scannedCode === code);
    const inCheckedItems = !!item;

    let inCart = false;
    if (!inCheckedItems) {
      // if item is in cart the app may have seen the same code we see now
      item = (items || []).find(i => i.scannedCode === code);
      inCart = !!item;
    }

    // if item is already checked and addDuplicates is false we do not increment
    if (inCheckedItems) {
      this.setState({ isValidating: false });
    } else if (inCart) {
      const found = inCart || item.isInCart;
      this.handleCheckedItems(item, found);
    // find codeTemplate and have a lookup
    } else {
      const { token, project, shopID } = this.props;
      const templates = findMatchingTemplates(code, codeTemplates, priceOverrideTemplates);
      lookUpWithTemplates(templates, project, shopID, token)
        .then((response) => {
          if (response.hasError) {
            this.setState({ isValidating: false });
            this.props.onError();
          } else if (response.found) {
            this.handleItemFromApi(response.product, code);
          } else if (!response.found) {
            this.showNotFound(code);
          }
        });
    }
  }

  render() {
    const {
      classes, onOpenCart, approveAction, updating, showActions, withHeading, minimalCount,
      isPartial,
    } = this.props;
    const {
      notFound, checkedItems, isValidating, foundInCart,
    } = this.state;

    return (
      <div className={classes.wrapper} ref={this.wrappingElement} tabIndex="0">
        {withHeading && <ProcessRescanHeading openCart={onOpenCart} />}

        <FailSound playSound={foundInCart === false} />
        <SuccessSound playSound={foundInCart} />
        <Scanner onContentReceive={value => this.validateItem(value)} />

        <div>
          {!checkedItems.length && (
            <Typography variant="h6" className={classes.status}>
              {!isPartial && <Translate id="rescan.instruction" />}
              {isPartial && <Translate id="rescan.partial" data={{ count: minimalCount }} />}
            </Typography>
          )}

          {notFound && notFound.length && (
            <Typography variant="h6" color="error" className={classes.status}>
              <Translate id="rescan.lookUpFailed" data={{ code: notFound }} />
            </Typography>
          )}
        </div>

        {isValidating && <div className={classes.loading}><CircularProgress /></div>}

        <ProcessRescanItemList
          items={checkedItems}
          updateCheckedItem={(sku, val) => this.updateCheckedItem(sku, val)}
        />

        <BundleSelectionLayer
          isVisible={this.state.showBundles}
          items={this.state.bundles}
          onSelection={this.handleBundleSelection}
        />

        {showActions &&
          <ProcessActions
            approveAction={() => approveAction(true, { checkedItems })}
            rejectAction={() => approveAction(false, { checkedItems })}
            disableButtons={updating}
          />
        }
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ProcessRescan));
