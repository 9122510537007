import { addApiHost } from './actions/urls';

const requestApi = async (match, project, shopID, token) => {
  const result = {
    found: false,
    product: {},
    hasError: false,
  };
  await fetch(
    addApiHost(`/${project}/supervising/resolvedProducts/lookUp?code=${match.parts.code}&template=${match.template}&shopID=${encodeURIComponent(shopID)}`),
    {
      headers: {
        'Client-Token': token,
        Accept: 'application/json',
      },
      method: 'GET',
    },
  )
    .then(
      async (response) => {
        if (response.status === 200) {
          await response.json().then((json) => {
            if (json.sku) {
              result.found = true;
              result.product = json;
            }
          });
        }
      },
      () => {
        result.hasError = true;
      },
    );
  return result;
};

const lookUpWithTemplates = async (templates, project, shopID, token) => {
  // map requests -> forEach cannot be used with async / await
  const requests = await templates.map(async match => requestApi(match, project, shopID, token));

  // after all requests are done we have a look at the results
  return Promise.all(requests).then((responses) => {
    const result = responses.find((response) => {
      if (response.hasError) return response;
      if (response.found) return response;
      return false;
    });

    return result || { found: false, product: {}, hasError: false };
  });
};

export default lookUpWithTemplates;
