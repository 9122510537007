// make react scripts work in ie11
import 'react-app-polyfill/ie11';
import 'core-js/es/array';
import 'core-js/es/string/starts-with';
import 'core-js/es/promise/finally';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

/* eslint react/jsx-filename-extension: "off" */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducedStore from './reducers';
import Root from './components/Root';
import theme from './theme';
import { decodeToken } from './token';
import './index.css';

const store = createStore(
  reducedStore,
  applyMiddleware(thunkMiddleware),
);

const token = decodeToken(document.cookie);
if (token) {
  store.dispatch({ type: 'ACQUIRED_TOKEN', payload: token });
}
ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Root />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root'),
);
