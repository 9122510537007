function join(...segments) {
  let parts = [];
  for (let i = 0, l = segments.length; i < l; i += 1) {
    parts = parts.concat(segments[i].split('/'));
  }

  const newParts = [];
  for (let i = 0, l = parts.length; i < l; i += 1) {
    if (parts[i] !== '') {
      newParts.push(parts[i]);
    }
  }

  if (parts[0] === '') {
    newParts.unshift('');
  }

  return newParts.join('/') || (newParts.length ? '/' : '.');
}

export default join;
