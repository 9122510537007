import React, { memo } from 'react';
import { ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { grey } from '@mui/material/colors';

import Price from './Price';

const styles = theme => ({
  textWrap: {
    display: 'flex',
    padding: 0,
    maxWidth: '750px',
    width: '100%',
    margin: '0 auto',
  },
  text: {
    width: '75%',
    display: 'flex',
  },
  name: {
    width: '75%',
    maxHeight: theme.spacing(8.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  amount: {
    color: theme.palette.primary.main,
    width: '25%',
  },
  weight: {
    width: '75%',
    maxHeight: theme.spacing(8.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: grey[600],
  },
  price: {
    width: '35%',
    textAlign: 'right',
    fontSize: '1rem',
    fontFamily: '\'Roboto Mono\', monospace',
  },
});

const LineItemListText = ({
  classes, amount, currency, isSecondary, lineItemText, price, referenceUnit,
}) => (
  <ListItemText
    classes={{ primary: classes.text, secondary: classes.price, root: classes.textWrap }}
    primary={
      <React.Fragment>
        <span className={classes.amount}> {!!amount && `${amount} ${String.fromCharCode(215)}`} </span>
        <span className={isSecondary ? classes.weight : classes.name}>
          {lineItemText}
        </span>
      </React.Fragment>
            }
    secondary={
      <React.Fragment>
        {!!price && <Price value={price} referenceUnit={referenceUnit} currency={currency} />}
      </React.Fragment>
    }
    />

);

export default memo(withStyles(styles)(LineItemListText));
