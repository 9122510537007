const pageTracking = () => {
  // IE save way to create custom event
  const trackPage = document.createEvent('CustomEvent');
  trackPage.initCustomEvent('supervisorRouteChange', false, false);
  dispatchEvent(trackPage);
};

const trackEvent = (category, action, name, value) => {
  // IE save way to create custom event
  const details = {
    category,
    action,
    name,
    value,
  };
  const trackingEvent = document.createEvent('CustomEvent');
  trackingEvent.initCustomEvent('supervisorEvent', false, false, details);
  dispatchEvent(trackingEvent);
};

export { pageTracking, trackEvent };
