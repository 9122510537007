import React from 'react';
import { Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Remove, Add } from '@mui/icons-material';
import { red, grey, green } from '@mui/material/colors';
import { trackEvent } from './tracking';

const styles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    minWidth: 0,
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    color: grey[700],
    background: grey[300],
    '&:hover': {
      background: grey[400],
    },
  },
  item: {
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  success: {
    textAlign: 'center',
    fontWeight: '500',
    color: green[900],
    margin: theme.spacing(2.5),
  },
  failure: {
    textAlign: 'center',
    fontWeight: '500',
    color: red[900],
    margin: theme.spacing(2.5),
  },
});

class ProcessRescanItemActions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.changeAmount = this.changeAmount.bind(this);
  }
  changeAmount(reduce) {
    let { amount } = this.props;
    if (reduce) {
      trackEvent('rescan', 'minusButton');
      if (amount === 0) return;
      amount -= 1;
    } else {
      trackEvent('rescan', 'plusButton');
      amount += 1;
    }
    this.props.setAmount(amount);
  }
  render() {
    const {
      classes, isSuccess, amount,
    } = this.props;
    return (
      <div className={classes.actions}>
        <Button
          variant="contained"
          classes={{ root: classes.btn }}
          onClick={() => this.changeAmount(true)}
        >
          <Remove />
        </Button>

        <Typography
          className={isSuccess ? classes.success : classes.failure}>
          {amount}
        </Typography>

        <Button
          variant="contained"
          classes={{ root: classes.btn }}
          onClick={() => this.changeAmount()}
    >
          <Add />
        </Button>
      </div>

    );
  }
}

export default withStyles(styles)(ProcessRescanItemActions);
