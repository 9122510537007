import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import AppContainer from '../containers/AppContainer';

const Root = () => (
  <LocalizeProvider>
    <Router>
      <Switch>
        <Redirect from="/supervisor/:projectID" to="/:projectID" />

        <Route exact path="/" component={AppContainer} />
        <Route path="/:projectID" component={AppContainer} />
      </Switch>
    </Router>
  </LocalizeProvider>
);

export default Root;
