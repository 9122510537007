import React from 'react';
import { ListItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { amber } from '@mui/material/colors';
import LineItemListText from './LineItemListText';
import ReferringItem from './ReferringItem';
import WeighableInformation from './WeighableInformation';

const styles = theme => ({
  item: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    flexWrap: 'wrap',
    flexDirection: 'column',
    [theme.breakpoints.up('640')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  highlight: {
    background: amber[500],
  },
});


const LineItemCell = ({
  classes, lineItem, currency, showSku, showScannedCode, referringItems,
}) => (
  <ListItem
    className={`${classes.item} ${lineItem.saleRestriction && classes.highlight} ${lineItem.forceControl && classes.highlight}`}
    divider
    disableGutters>

    <LineItemListText
      price={!lineItem.weight && !lineItem.units ? lineItem.totalPrice : null}
      referenceUnit={lineItem.referenceUnit}
      currency={currency}
      amount={lineItem.amount}
      lineItemText={lineItem.name || lineItem.sku}
    />

    {showSku &&
      <LineItemListText
        lineItemText={lineItem.sku}
        isSecondary
      />
    }

    {showScannedCode &&
      <LineItemListText
        lineItemText={lineItem.scannedCode}
        isSecondary
      />
    }

    <WeighableInformation
      lineItem={lineItem}
      currency={currency}
    />

    {(referringItems || []).map(item => (
      <ReferringItem
        key={item.id}
        item={item}
        currency={currency}
      />
    ))}
  </ListItem>
);

export default withStyles(styles)(LineItemCell);
