import React from 'react';
import ProcessGrid from './processGrid/ProcessGrid';
import ProcessList from './ProcessList';
import { Process } from './ProcessListView';

export interface ProcessSplitViewProps {
  processes: Process[]
  onProcessClicked: any;
}

export default function ProcessSplitView({ processes, onProcessClicked }: ProcessSplitViewProps) {
  const splitViewStyle = {
    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: '2fr 1fr',
  };

  return (
    <>
      <div style={{
        ...splitViewStyle,
        height: '40px',
        color: '#07b',
        fontSize: '14px',
        fontWeight: '500',
      }}
      >
        <span style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '12px',
        }}
        >
          SELF-CHECKOUT
        </span>
        <span style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '12px',
        }}
        >
          APP
        </span>
      </div>

      <div style={splitViewStyle}>
        <ProcessGrid scoProcesses={processes.filter(p => !!p.checkoutDeviceID)} />
        <ProcessList
          onProcessClicked={(process: Process) => onProcessClicked(process, 'linkToProcess')}
          processes={processes.filter(p => !p.checkoutDeviceID)}
        />
      </div>
    </>
  );
}
