import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { LocaleDateFromNow } from './LocaleDate';

const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing(-8.5),
    textAlign: 'center',
  },
  refresh: {
    padding: '10px 0 0',
    display: 'block',
  },
  last: {
    padding: '10px 0 5px 0',
    display: 'block',
  },
});


class ProcessLastUpdate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };
  }

  componentDidMount() {
    this.startUpdateTextTimeout();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startTimer !== this.props.startTimer) {
      this.handleText();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.textTimer);
  }

  handleText() {
    const { startTimer, onTimeout } = this.props;
    if (startTimer) {
      this.timer = setTimeout(() => {
        onTimeout();
      }, 5000);
    } else {
      clearTimeout(this.timer);
    }
  }

  startUpdateTextTimeout() {
    this.textTimer = setTimeout(() => {
      this.updateText();
      this.startUpdateTextTimeout();
    }, 3000);
  }

  updateText() {
    const lastUpdate = moment(this.props.lastUpdate);
    let display = '';
    if (lastUpdate.isValid) {
      display = LocaleDateFromNow(lastUpdate);
    }
    this.setState({ text: display });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Typography align="center" variant="caption" className={classes.refresh}>
          <Translate id="general.refresh" />
        </Typography>
        <Typography align="center" variant="caption" className={classes.last}>
          <Translate id="process.lastUpdate" data={{ updateTime: this.state.text }} />
        </Typography>
      </div>
    );
  }
}


export default withStyles(styles)(ProcessLastUpdate);
