import React, { memo } from 'react';
import { Translate } from 'react-localize-redux';
import { Tabs, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { trackEvent } from './tracking';

const styles = {
  wrapper: {
    marginTop: 'auto',
    marginLeft: 'auto',
    width: 'calc(100% - 54px)', // 36 = menu btn, 18 = space
  },
  tabRoot: {
    minHeight: '64px',
    textTransform: 'none',
    width: '25%',
  },
  selected: {
    background: '#015686',
  },
  indicator: {
    height: '4px',
  },
};


const ProcessTabs = ({
  classes, tabs, shopID, activeTab, changeTab,
}) => {
  if (!tabs.length) return null;

  return (
    <div className={classes.wrapper}>
      <Tabs value={activeTab} classes={{ indicator: classes.indicator }} textColor="inherit" indicatorColor="secondary">
        {(tabs || []).map((tab, index) =>
          (
            <Tab
              classes={{ root: classes.tabRoot, selected: classes.selected }}
              onClick={() => {
                changeTab(`${tab.link}shopID=${shopID}`, index);
                trackEvent('process-list', 'tabClick', tab.translationKey);
              }}
              label={
                <Translate
                  id={`mainnavigation.${tab.translationKey}`}
                  options={{ onMissingTranslation: () => tab.fallbackTranslation }}
                />}
              key={tab.translationKey}
            />
          ))}
      </Tabs>
    </div>
  );
};

export default memo(withStyles(styles)(ProcessTabs));
