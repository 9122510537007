
function updatedSubscribedList(listUrl) {
  return {
    type: 'UPDATED_SUBSCRIBED_LIST',
    payload: listUrl,
  };
}

function updatedSubscribedProcess(url) {
  return {
    type: 'UPDATED_SUBSCRIBED_PROCESS',
    payload: url,
  };
}

export {
  updatedSubscribedProcess,
  updatedSubscribedList,
};
