import React from 'react';
import { Translate } from 'react-localize-redux';
import { Snackbar } from '@mui/material';

const NotificationArea = ({
  open, message, onClose, isError,
}) => {
  if (!message) return null;
  const duration = isError ? 10000 : 5000;
  const onMissingTranslation = () => message;
  const translation =
    <Translate id={message} options={{ ignoreTranslateChildren: true, onMissingTranslation }} />;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      message={translation}
    />
  );
};

export default NotificationArea;
